// modules
import React, { useEffect } from 'react';

// components
import { makeStyles, Drawer, Divider } from '@material-ui/core';
import { Navigation } from 'components';

// hooks
import useRouter from 'utils/useRouter';
import { useCurrentUser } from 'hooks';

// assets
import { rolesConfig } from 'assets/rolesConfig';

// utils
import clsx from 'clsx';

// config
import navigationConfig from './navigationConfig';
import cn from 'classnames';

const DRAWER_WIDTH = 256;

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {},
  drawerPaper: {
    position: 'relative',
    width: DRAWER_WIDTH,
    zIndex: 1202,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-button': {
      display: `none`,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey['100'],
      borderRadius: 1,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey.A200,
      borderRadius: 1,
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

const DashboardNavBar = (props) => {
  const { isOpen, onClose, className, ...rest } = props;

  const css = useStyles();
  const router = useRouter();

  const { hasUser, user } = useCurrentUser();

  // collapse menu on navigation
  useEffect(() => {
    if (isOpen) {
      if (onClose) {
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  // methods
  const filterPages = (page) => {
    if (!hasUser) {
      return {};
    }

    const hasReadAccess = rolesConfig[page.key]?.read[user.role];

    return hasReadAccess;
  };

  // jsx
  const renderMenuItem = (list, index) => {
    const filteredPages = list.pages.filter(filterPages);

    if (filteredPages.length) {
      return (
        <React.Fragment key={list.key}>
          {index > 0 && <Divider />}

          <Navigation key={list.key} component="div" isOpen={isOpen} pages={filteredPages} title={list.title} />
        </React.Fragment>
      );
    }

    return null;
  };

  const navbarContent = (
    <div className={css.content}>
      <nav className={css.navigation}>{navigationConfig.map(renderMenuItem)}</nav>
    </div>
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={isOpen}
        classes={{
          paper: cn(css.drawerPaper, { [css.drawerPaperClose]: !isOpen }),
        }}
        variant="permanent"
        onClose={onClose}
      >
        <div {...rest} className={clsx(css.root, className)}>
          {navbarContent}
        </div>
      </Drawer>
    </>
  );
};

export { DashboardNavBar };

import { useCurrentUser } from './useCurrentUser';
import moment from 'moment';

export const RU_DATE_FORMAT = 'DD.MM.YYYY';
export const EN_DATE_FORMAT = 'DD/MM/YYYY';

export const DEFAULT_FORMAT_FROM_SERVER = 'YYYY-MM-DD';

const useFormatDate = () => {
  const { user } = useCurrentUser();
  const { locale } = user;

  const formatDate = (date, DATE_FORMAT = DEFAULT_FORMAT_FROM_SERVER) => {
    if (date) {
      return moment(date, DATE_FORMAT).format(locale === 'RU' ? RU_DATE_FORMAT : EN_DATE_FORMAT);
    }

    return null;
  };

  return { formatDate };
};

export { useFormatDate };

// modules
import React, { useState } from 'react';

// components
import { makeStyles, Button, Menu, MenuItem } from '@material-ui/core';

// hooks
import { useCurrentUser } from 'hooks';
import { useMutationWithNotification } from 'hooks/customApollo';

// graphql
import { changeLanguage as changeLanguageMutation } from 'graphql/requests/language';

// utils
import cn from 'classnames';

// assets
import { DEFAULT_LANGUAGE, EN, RU } from 'assets/constants';

// styles
const useStyles = makeStyles(() => ({
  btn: {
    color: '#fff',
  },
  menu: {
    marginTop: 35,
  },
}));

const LanguageSelect = (props) => {
  const { className } = props;

  const css = useStyles();

  // state
  const [openLanguageMenu, setLanguageMenu] = useState(null);

  // graphql
  const [changeLanguage] = useMutationWithNotification(changeLanguageMutation, {
    shouldNotifyOnSuccess: false,
  });

  // custom hooks
  const { hasUser } = useCurrentUser();

  // constants
  const currentLang = localStorage.getItem('lang') || DEFAULT_LANGUAGE;

  const handleLanguageChange = async (newLang) => {
    if (currentLang === newLang) {
      return;
    }

    if (hasUser) {
      await changeLanguage({ variables: { locale: newLang.toUpperCase() } });
    }

    localStorage.setItem('lang', newLang);

    window.location.reload();
  };

  return (
    <div className={cn(className)}>
      <Button className={css.btn} onClick={(e) => setLanguageMenu(e.currentTarget)}>
        {currentLang}
      </Button>

      <Menu
        id="language-menu"
        className={css.menu}
        anchorEl={openLanguageMenu}
        open={!!openLanguageMenu}
        onClose={() => setLanguageMenu(null)}
      >
        <MenuItem disabled={currentLang === RU} onClick={() => handleLanguageChange(RU)}>
          RU
        </MenuItem>

        <MenuItem disabled={currentLang === EN} onClick={() => handleLanguageChange(EN)}>
          EN
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(LanguageSelect);

import { roles as allRoles } from 'assets/constants';

/**
 *
 * @param {object} roles
 */
const onlyForRoles = (roles) => {
  const { NETWORK_MAIN_USER, NORMAL_MAIN_USER, NORMAL_USER } = roles;

  return {
    ADMIN_USER: true,
    NETWORK_MAIN_USER: Boolean(NETWORK_MAIN_USER),
    NORMAL_MAIN_USER: Boolean(NORMAL_MAIN_USER),
    NORMAL_USER: Boolean(NORMAL_USER),
  };
};

const ADMIN_ONLY = {};
const { NETWORK_MAIN_USER, NORMAL_MAIN_USER, NORMAL_USER } = allRoles;

export const rolesConfig = {
  accounts: {
    read: onlyForRoles({ NETWORK_MAIN_USER }),
    create: onlyForRoles({ NETWORK_MAIN_USER }),
    edit: onlyForRoles({ NETWORK_MAIN_USER }),
  },
  users: {
    read: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER }),
    create: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER }),
    edit: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER }),
  },
  sites: {
    read: onlyForRoles(allRoles),
    create: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER, NORMAL_USER }),
    edit: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER, NORMAL_USER }),
  },
  units: {
    read: onlyForRoles(allRoles),
    create: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER, NORMAL_USER }),
    edit: onlyForRoles({ NETWORK_MAIN_USER, NORMAL_MAIN_USER, NORMAL_USER }),
  },
  reports: {
    read: onlyForRoles(allRoles),
    create: onlyForRoles(allRoles),
    edit: onlyForRoles(allRoles),
  },
  payments: {
    read: onlyForRoles(ADMIN_ONLY),
    create: onlyForRoles(ADMIN_ONLY),
    edit: onlyForRoles(ADMIN_ONLY),
  },
  ord: {
    read: onlyForRoles(ADMIN_ONLY),
    create: onlyForRoles(ADMIN_ONLY),
    edit: onlyForRoles(ADMIN_ONLY),
  },
};

// Modules
import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
// Components
import { Search, Filter } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
  },
  filterButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
}));

const SearchBar = (props) => {
  const { onFilter, filter, searchValue, onSearch, className, ...rest } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid {...rest} className={clsx(classes.root, className)} container>
      <Grid item>
        <Search className={classes.search} value={searchValue} onChange={onSearch} />
      </Grid>
      {filter && (
        <>
          <Grid item>
            <Button
              className={classes.filterButton}
              color="primary"
              onClick={handleFilterOpen}
              size="small"
              variant="outlined"
            >
              <FilterListIcon className={classes.filterIcon} /> Show filters
            </Button>
          </Grid>
          <Filter onClose={handleFilterClose} onFilter={onFilter} open={openFilter} />
        </>
      )}
    </Grid>
  );
};

export default SearchBar;

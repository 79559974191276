// modules
import React, { useState, useMemo } from 'react';

// components
import { makeStyles } from '@material-ui/core';
import Notification from './Notification';
import { createPortal } from 'react-dom';

// context
import { NotificationsContext } from './NotificationsContext';

// styles
const useStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    right: 0,
    top: 80,
    width: 350,
    paddingRight: 10,
    zIndex: 10000,
  },
  notificationChild: {
    margin: '5px 0',
  },
}));

// static
const variants = ['error', 'warning', 'info', 'success'].reduce((obj, color) => {
  return { ...obj, [color]: color };
}, {});

const NotificationsCenter = (props) => {
  const { children } = props;

  const css = useStyles();

  // state
  const [notifications, setNotifications] = useState([]);

  // methods
  const removeNotification = (id) => {
    setNotifications((old) => old.filter((item) => item.id !== id));
  };

  const pushNotification = (newNotification) => {
    const id = new Date().valueOf();
    setNotifications((old) => [...old, { ...newNotification, id }]);
  };

  // memoized value
  const contextValue = useMemo(() => {
    return { pushNotification };
  }, []);

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}

      {createPortal(
        <div className={css.container}>
          {notifications.map((notification) => {
            const { children, id, variant, title, body } = notification;
            const color = variants[variant] ?? 'info';

            return (
              <Notification
                key={id}
                color={color}
                nestedNotifications={children}
                severity={color}
                onRemove={() => removeNotification(id)}
              >
                <strong>{title}</strong>
                {!children && <div>{body}</div>}
              </Notification>
            );
          })}
        </div>,
        document.body
      )}
    </NotificationsContext.Provider>
  );
};

export default NotificationsCenter;

// modules
import React from 'react';

// components
import { makeStyles } from '@material-ui/core';
import { AuthTopBar } from './AuthTopBar';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
}));

const AuthLayout = (Component) => (props) => {
  return (
    <Auth>
      <Component {...props} />
    </Auth>
  );
};

const Auth = (props) => {
  const css = useStyles();

  return (
    <>
      <AuthTopBar />

      <main className={css.content}>{props.children}</main>
    </>
  );
};

export { AuthLayout };

// modules
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// context
import { NotificationsContext } from 'components/NotificationsCenter/NotificationsContext';

// utils
import { capitalize } from 'lodash';

// assets
import i18n from 'i18n/consts';

export const useGraphqlResponseParser = (props) => {
  const { response, entity, operationName, shouldNotifyOnError, shouldNotifyOnSuccess } = props;

  const { data, loading, error } = response;
  const { pushNotification } = useContext(NotificationsContext);

  const { t } = useTranslation();

  /* push error notification */
  useEffect(() => {
    if (!error || !shouldNotifyOnError || !pushNotification) {
      return;
    }

    const { message = '', debugMessage = '' } = error?.graphQLErrors?.[0] || {};

    const body = `${debugMessage || message}`.trim();

    const children =
      error?.graphQLErrors?.length > 1 ? error?.graphQLErrors.slice(1).map((err) => ({ title: err?.message })) : null;

    pushNotification({ title: t(i18n.serverError), variant: 'error', body: capitalize(body), children });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /* push success notification */
  useEffect(() => {
    if (!shouldNotifyOnSuccess) {
      return;
    }

    const label = entity || t(i18n.data);
    const upperEntity = `${label[0].toUpperCase()}${label.slice(1)}`;

    if (data && !loading && !error) {
      const title = `${upperEntity} ${operationName || t(i18n.saved_plural)}`;
      pushNotification({ title: capitalize(title), variant: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  return null;
};

// types
useGraphqlResponseParser.propTypes = {
  response: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
    loading: PropTypes.bool,
    error: PropTypes.objectOf(PropTypes.any),
  }),
  entity: PropTypes.string,
  operationName: PropTypes.string,
};

useGraphqlResponseParser.defaultProps = {
  response: {
    data: null,
    loading: false,
    error: null,
  },
  entity: '',
  operationName: '',
};

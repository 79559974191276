const CONFIG_URL = '/config.json';

class ConfigService {
  REACT_APP_API_URL = 'https://ui-api.ssp.otm-r.com/graphql';

  REACT_APP_REPORTING_ENDPOINT = 'https://reports.ssp.otm-r.com/api/v1';

  REACT_APP_HTTP_URL = 'http://ui-api.ssp.otm-r.test/';

  /**
   * Fetch and replace config values
   * @return {Promise<Response | void>}
   */
  init = () =>
    fetch(CONFIG_URL)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`Response to ${CONFIG_URL} not ok`);
      })
      .then((response) => {
        if (typeof response === 'object' && response !== null) {
          Object.entries(response).forEach(([key, value]) => {
            if (!['init'].includes(key)) {
              this[key] = value;
            }
          });
        }
      });
}

export const configService = new ConfigService(); // singleton

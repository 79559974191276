// modules
import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

// components
import { makeStyles, CircularProgress } from '@material-ui/core';
import { NotificationsCenter } from 'components';

// hooks
import { useCurrentUser } from 'hooks';
import { useMutationWithNotification } from 'hooks/customApollo';

// graphql
import { currentUser as currentUserQuery } from 'graphql/requests/auth';
import { changeLanguage as changeLanguageMutation } from 'graphql/requests/language';

// router
import useRouter from 'utils/useRouter';
import { Routes } from 'routing/Routes';

import { DEFAULT_LANGUAGE } from 'assets/constants';

// styles
const useStyles = makeStyles((theme) => ({
  '@global': {
    '.primaryLink': {
      color: theme.palette.primary.main,
      textDecoration: 'none !important',
      cursor: 'pointer',

      '&:active': {
        color: theme.palette.primary.main,
      },

      '&:visited': {
        color: theme.palette.primary.main,
      },
    },
  },

  loader: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
}));

const App = () => {
  const css = useStyles();

  const router = useRouter();

  // hooks
  const { hasUser, user, loading } = useCurrentUser();

  const client = useApolloClient();

  const currentLang = localStorage.getItem('lang') || DEFAULT_LANGUAGE;

  const [changeLanguage] = useMutationWithNotification(changeLanguageMutation, {
    shouldNotifyOnSuccess: false,
    onCompleted: (res) => {
      if (res.data?.changeLanguage) {
        client.writeQuery({
          query: currentUserQuery,
          data: { me: { ...user, locale: currentLang.toUpperCase() } },
        });
      }
    },
  });

  // state
  const [isCurrentUserLoading, setCurrentUserLoading] = useState(loading);

  // effects
  useEffect(() => {
    function changeLanguageIfNeed() {
      if (hasUser && user.locale?.toLowerCase() !== currentLang) {
        changeLanguage({ variables: { locale: currentLang.toUpperCase() } });
      }
    }

    changeLanguageIfNeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.location.pathname]);

  useEffect(() => {
    if (loading) {
      setCurrentUserLoading(loading);
    } else {
      setTimeout(() => setCurrentUserLoading(loading), 100); // back to normal with delay to update theme
    }
  }, [loading]);

  if (isCurrentUserLoading) {
    return (
      <div className={css.loader}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <NotificationsCenter>
      <Routes />
    </NotificationsCenter>
  );
};

export default App;

// modules
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import PeopleIcon from '@material-ui/icons/People';
import TableChartIcon from '@material-ui/icons/TableChart';
import WebIcon from '@material-ui/icons/Web';

// i18n
import i18n from 'i18n/consts';

export default [
  {
    key: 'main',
    pages: [
      {
        key: 'accounts',
        href: '/accounts/list',
        icon: AccountBoxIcon,
        title: i18n.accounts,
      },
      {
        key: 'users',
        href: '/users/list',
        icon: PeopleIcon,
        title: i18n.users,
      },
      {
        key: 'sites',
        href: '/sites/list',
        icon: WebIcon,
        title: i18n.sites,
      },
      {
        key: 'units',
        href: '/units/list',
        icon: FeaturedVideoIcon,
        title: i18n.units,
      },
      {
        key: 'payments',
        href: '/payments',
        icon: AccountBalanceIcon,
        title: i18n.paymentsMenuLabel,
      },
      {
        key: 'ord',
        href: '/ord/invoice',
        icon: DescriptionOutlinedIcon,
        title: i18n.ord,
      },
    ],
  },
  {
    key: 'secondary',
    pages: [
      {
        key: 'reports',
        href: '/reports',
        icon: TableChartIcon,
        title: i18n.reports,
      },
    ],
  },
];

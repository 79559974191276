const consts = Object.freeze({
  // general
  update: 'Update',
  backToHome: 'Back to home',
  logout: 'Logout',
  serverError: 'Server error',
  data: 'Data',
  saved: 'Saved',
  saved_plural: 'Saved',
  unknown: 'Unknown',
  unknown_she: 'Unknown',
  error: 'Error',
  copiedToClipboard: 'Copied to clipboard',
  delete: 'Delete',
  apply: 'Apply',
  parameters: 'Parameters',

  errors: 'Errors',
  warnings: 'Warnings',
  noErrors: 'No errors',
  request: 'Request',
  response: 'Response',

  policy: 'Policy',
  invalidFields: 'Invalid fields',

  blockedAdvertiserDomains: 'Blocked advertiser domains',
  type: 'Type',
  noData: 'No records to display',
  name: 'Name',
  entityName: 'Name',
  active: 'Active',
  revenue_optimiser: 'Revenue optimiser',
  inactive: 'Inactive',
  create: 'Create',
  createNew: 'Create new',
  createNewRus: 'Create new ',
  edit: 'Edit',
  search: 'Search',
  prev: 'Previous',
  next: 'Next',
  status: 'Status',
  placement: 'Placement',
  inStream: 'In-Stream',
  inBanner: 'In-Banner',
  inArticle: 'In-Article',
  inFeed: 'In-Feed',
  interstitial: 'Interstitial/Slider/Fly-Roll',
  newFirst: 'Newest first',
  oldFirst: 'Oldest first',
  sortAZ: 'A-Z',
  sort: 'Sort',
  yes: 'Yes',
  no: 'No',
  fail: 'An error occured. Please try again later',
  validationError: 'Validation error',
  undefinedError: 'Undefined error',
  new: 'New',
  language: 'Language',
  reports: 'Reports',
  format: 'Format',
  save: 'Save',
  saving: 'Saving',
  cancel: 'Cancel',
  preview: 'Preview',
  webSite: 'Website',
  app: 'App',
  paymentsMenuLabel: 'Payments',
  year: 'Year',
  month: 'Month',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  impressions: 'Impressions',
  supplyRevenue: 'Supply revenue',
  dspPayments: 'DSP payments',
  paymentsMargin: 'Margin',
  payoutDiff: 'Payout difference (DSP)',
  paymentsClosed: 'Status',
  report: 'Report',
  total: 'Total',
  revenue: 'Revenue',
  finalize: 'Finalize',
  download: 'Download',
  supplier: 'Supplier',
  payoutDiffSupplier: 'Payout difference (Supplier)',
  refresh: 'Refresh',
  dated: 'Dated',
  withoutNumShort: 'w/n',
  noOptions: 'No options',

  typeAtLeastCharsCount_one: 'Type at least {{count}} char',
  typeAtLeastCharsCount_few: 'Type at least {{fewCount}} chars',
  typeAtLeastCharsCount_many: 'Type at least {{manyCount}} chars',

  // error pages
  errorTitle401: 'We are sorry but we are not able to authenticate you',
  errorTitle404: 'There is no such page',
  errorTitle500: 'Ooops, something went wrong!',

  // log in page
  logInHeader: 'Log into your personal account',
  logIn: 'Login',
  failedLogIn: 'Login failure',
  loggingIn: 'Logging in',
  password: 'Password',
  forgotPassword: 'Forgot password',
  register: 'Register',
  registration: 'Registration',
  goBack: 'Go back',
  restorePassword: 'Restore password',
  restore: 'Restore',
  restoreSuccess: 'You will receive an email with restore password instructions shortly',
  resetPassword: 'Reset password',
  reset: 'Reset',
  enterEmail: 'Enter your email address',
  emailConfirmation: 'Email confirmation',
  registerSuccess:
    'Registration completed successfully. We sent a confirmation mail to your email. Please confirm your email to start using our service',
  confirmSuccess: 'Your email address has been confirmed successfully. You can go back to the home page to login.',
  changePassSuccess: 'Your password has been changed successfully. You can go back to the home page to login',
  verificateAgain: 'Your email is not verified. Please check your mailbox. We also can send verification mail again',
  sendAgain: 'Send again',
  mailSent: 'Mail sent to your mailbox',
  verificateSent: 'A confirmation email sent to your mailbox successfuly',
  wrongCredentials: 'Email or password is invalid',
  agreePolicy: 'By checking I agree with personal <0>data processing policy</0>',

  // accounts
  accounts: 'Accounts',
  account: 'Account',
  dsp: 'DSP',
  ssp: 'SSP',
  supplyManager: 'Supply manager',
  hostAccount: 'Host account',
  chooseAccount: 'Choose an account',
  accountType: 'Account type',
  accountName: 'Account name',
  counterparty: 'Counterparty',
  accountCommission: 'Account commission',
  accountUsers: 'Account users',
  noAccountUsers: 'No users of this account',
  paymentInfo: 'Payment info',
  chooseAsMain: 'Choose as main',
  domain: 'Domain',
  trackingUrl: 'Impressions tracking URL',
  chooseLogo: 'Choose a logo',
  network: 'Network',
  local: 'Local',
  accountDomain: 'Account domain',
  legalEntity: 'Legal Entity',
  creationDate: 'Creation date',
  updateDate: 'Update date',
  registrationDate: 'Registration date',

  // users
  users: 'Users',
  user: 'User',
  userRus: 'User ',
  userStatus: 'User status',
  fullName: 'Full name',
  readOnly: 'Read only',
  adminPermissions: 'Admin permissions',
  newPassword: 'New password',
  passwordConfirmation: 'Password confirmation',
  sendInvitation: 'Send invitation',

  // sites
  sites: 'Sites',
  site: 'Site',
  siteName: 'Site name',
  siteUrl: 'Site url',
  siteType: 'Site type',
  defaultMinCPM: 'Default Min CPM',

  // units
  units: 'Units',
  unit: 'Unit',
  shortUnit: 'Unit',
  unitName: 'Unit name',
  size: 'Size',
  height: 'Height',
  width: 'Width',
  responsive: 'Responsive',
  fullscreen: 'Fullscreen',
  htmlCodePlug: 'Stub (passback) code (HTML)',
  inheritSiteSettings: 'Inherit site settings',
  maxDuration: 'Max Duration',
  setupCode: ' Tag code ',
  redact: 'Edit',
  close: 'Close',
  minCpm: 'Min CPM',
  vastPlugUrl: 'VAST passback URL address',
  passbackStrategy: 'Passback',
  hideStrategy: 'Hide ad unit when there are no ads',
  repeatStrategy: 'Repeat ad request',
  customStrategy: 'Custom setting',
  wrongHtmlStub: 'Passback code(HTML) is required',
  wrongVastUrl: 'Wrong VAST passback URL',
  chooseUnitType: 'Choose unit type',
  bannerDesc: 'A common customizable rectangular banner',
  videoInstreamDesc: 'Good for monetization of videos that are already present on a website. Advertising format - VAST',
  videoOutstreamDesc:
    'A big rectangular banner that includes ad video player. A video ad starts playing automatically when comes into view (becomes visible)',
  videoFlyrollDesc:
    'Outstream placement (video ads inside article) without linking to the video content. When scrolling, ad player moves as a smaller version to the bottom corner of the screen, continuing to show advertising',
  audioInstream:
    'Good for monetization of audio records  that are already present on a website. Advertising format - VAST',
  statusActive: 'Active',
  statusInactive: 'Inactive',
  statusTest: 'Test',
  availableVastTagParameters: 'Available Vast Tag parameters',

  // reporting
  clearAll: 'Clear all',
  showColumns: 'Show columns',

  // ord
  ord: 'Ord',
  contracts: 'Contracts',
  invoices: 'Invoices',
  import: 'Import',
  export: 'Export',
  newExport: 'New export',
  exports: 'Exports',
  noAvailablePeriods: 'There are no available periods',
  tokens: 'Tokens',

  // ord contracts
  createNewContract: 'Create new contract',
  editContract: 'Edit contract',

  // ord invoices
  amountOf: '{{entity}} amount',
  invoicesUploaded: 'Invoices uploaded',
  checking: 'Checking',
  readyToSend: 'Ready to send',
  wereSentToOrd: 'Sent to ORD',

  warningsAndErrors: 'Warnings and errors',

  uploadInvoicesFrom1c: 'Upload invoices from 1C',
  uploadHistory: 'Upload history',
  fileSuccessfullyUploaded: 'File successfully uploaded',
  errorDuringFileUpload: 'Error during file upload',
  uploadErids: '+ Upload',
  file: 'File',
  filePath: 'File path',
  uploaded: 'Uploaded',
  updateOtmDspErids: 'Upload OTM DSP data',

  eridSource: 'Source',
  EridExportReportSourceEnum: {
    OTM_EXCHANGE: 'OTM Exchange',
    OTM_DSP: 'OTM DSP',
  },

  fileUploadErrorId: 'File upload error #{{fileId}}',
  exception: 'Exception',
  logs: 'Logs',

  counterpartyType: 'Counterparty type',
  invoiceNumber: 'Invoice num',
  invoiceDate: 'Invoice date',
  invoicePeriod: 'Invoice period',
  invoiceStatus: 'Invoice status',
  contract: 'Contract',
  invoiceAmount: 'Invoice amount',
  invoiceVATAmount: 'VAT amount',
  invoiceWithoutVATAmount: 'Invoice amount w/o VAT',
  includesVAT: 'Includes VAT',
  statAmount: 'Stat amount',
  invoicesTotalWithoutVAT: 'Invoices amount w/o VAT',
  erirStatus: 'ERIR status',
  confirmDeleteInvoice: 'Are you sure you want to delete this invoice ({{- description}})?',
  confirmDeleteInvoiceRow: 'Are you sure you want to delete invoice ({{- description}}) row with sum {{sum}}?',
  customer: 'Customer',
  customerRole: 'Customer role',
  executor: 'Executor',
  executorRole: 'Executor role',

  numberAndDateOfInitialContract: 'Number / Date of initial contract',
  amountLinkedToOriginalContract: 'Amount linked to original contract',

  temporaryInvoice: 'Temporary invoice',
  createTemporaryInvoiceForId: 'Create temporary invoice for ID #{{id}}',

  selectExistingContract: 'Select existing contract',
  orCreateTempInvoiceBasedOnNewContract: 'Or create temporary invoice based on new contract',

  existingContract: 'Existing contract',
  newContract: 'New contract',

  modifyInvoice: 'Modify invoice',
  startOfReportingPeriod: 'Start of reporting period',
  endOfReportingPeriod: 'End of reporting period',

  contractNumber: 'Contract number',
  contractDate: 'Contract date',
  clientType: 'Client type',
  client: 'Client',
  ordId: 'ORD ID',

  INVOICE_STATUS_DRAFT: 'Draft',
  INVOICE_STATUS_QUEUED: 'Queued for processing',
  INVOICE_STATUS_PROCESSING: 'Processing',
  INVOICE_STATUS_PROCESSED: 'Processed',
  INVOICE_STATUS_READY: 'Ready for ORD send',
  INVOICE_STATUS_NOT_READY: 'Not ready for ORD send',
  INVOICE_STATUS_SENDING: 'Sending to ORD',
  INVOICE_STATUS_SENT: 'Sent to ORD',
  INVOICE_STATUS_ERROR: 'Error',

  INVOICE_ROLE_AGENCY: 'Agency',
  INVOICE_ROLE_DISTRIBUTOR: 'Distributor',
  INVOICE_ROLE_OPERATOR: 'Operator',
  INVOICE_ROLE_ADVERTISER: 'Advertiser',

  ERIR_STATUS_WAIT: 'Queued for export',
  ERIR_STATUS_REVIEW: 'On review',
  ERIR_STATUS_ACCEPTED: 'Accepted',
  ERIR_STATUS_ERROR: 'Error',

  NEW: 'New',
  PROCESS: 'In process',
  SUCCESS: 'Success',
  ERROR: 'Error',

  invoiceActionProcess: 'Queue for processing',
  invoiceActionSend: 'Send to ORD',
  invoiceActionDraft: 'Change to Draft',
  invoiceActionOpenReport: 'Open invoice report',

  invoiceWasRemoved: 'Invoice was removed',
  rowWasRemoved: 'Row was removed',

  sheet: 'Sheet',
  row: 'Row',
  isSspInvoice: 'Resolved with SSP invoice',
});

export default consts;

import { useMutation } from '@apollo/react-hooks';
import { useGraphqlResponseParser } from './useGraphqlResponseParser';

export const useMutationWithNotification = (mutation, opts = {}) => {
  const { entity, operationName, shouldNotifyOnError = true, shouldNotifyOnSuccess = true, ...options } = opts;

  const mutationResponse = useMutation(mutation, options);

  useGraphqlResponseParser({
    response: mutationResponse[1],
    entity,
    operationName,
    shouldNotifyOnError,
    shouldNotifyOnSuccess,
  });

  return mutationResponse;
};

// modules
import React, { useRef, useState } from 'react';
import PT from 'prop-types';

// components
import { makeStyles, InputAdornment, TextField } from '@material-ui/core';
import { DateRangePicker as MDateRangePicker, DatePicker as MDatePicker } from '@material-ui/pickers';

import { Event as EventIcon } from '@material-ui/icons';

// hooks
import { useCurrentUser } from 'hooks';

// utils
import cn from 'classnames';
import moment from 'moment';

// styles
import './index.scss';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },

  wrapper: {
    display: 'flex',
    gap: '16px',

    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
      padding: 8,
    },
  },

  adornment: {
    pointerEvents: 'none',
  },
}));

const DatePicker = (props) => {
  const {
    isGrow,
    wrapperClassName,
    className,

    inputFormat,
    valueFormat,
    inputMask,

    error,
    isColumnDirection,
    isRange,
    label,
    size,
    startError,
    stopError,
    startLabel,
    stopLabel,
    startWarn,
    stopWarn,
    warn,
    variant,
    value,

    onChange,

    ...otherProps
  } = props;

  const css = useStyles();
  const { user } = useCurrentUser();

  // state
  const [isOpen, setIsOpen] = useState(false);

  // refs
  const anchorRef = useRef();
  const inputRef = useRef();
  const popperElementRef = useRef();

  // constants
  const locale = user.locale === 'RU' ? 'ru-RU' : 'en-GB';

  /* для того чтобы использовать свой формат, необходимо прокинуть inputFormat и inputMask */
  /* если для value необходим другой формат, необходимо прокинуть valueFormat */
  const finalInputFormat = inputFormat || (locale === 'en-GB' ? 'DD/MM/YYYY' : 'DD.MM.YYYY');
  const finalInputMask = inputMask || (locale === 'en-GB' ? '__/__/____' : '__.__.____');

  const finalValueFormat = valueFormat || 'YYYY-MM-DD';

  // render functions
  const endAdornment = (
    <InputAdornment className={css.adornment} position="end">
      <EventIcon color="action" />
    </InputAdornment>
  );

  const renderDateFields = (propsForStart, propsForEnd) => {
    if (isRange) {
      const { error, ref, ...startProps } = propsForStart;
      const { error: _, ref: _ref, ...endProps } = propsForEnd;

      return (
        <div ref={inputRef} className={cn(css.wrapper, wrapperClassName)}>
          <TextField
            className={className}
            error={startError}
            {...startProps}
            InputProps={{ endAdornment }}
            helperText={null}
            size={size}
            variant={variant}
          />

          <TextField
            className={className}
            error={stopError}
            {...endProps}
            InputProps={{ endAdornment }}
            helperText={null}
            size={size}
            variant={variant}
          />
        </div>
      );
    }

    return (
      <div ref={inputRef} className={cn(css.wrapper, wrapperClassName)}>
        <TextField
          ref={inputRef}
          className={className}
          {...propsForStart}
          error={error || startError}
          helperText={null}
          size={size}
          variant={variant}
        />
      </div>
    );
  };

  // methods
  const handleChange = (value) => {
    const start = value[0] ? moment(value[0], finalInputFormat).format(finalValueFormat) : '';
    const stop = value[1] ? moment(value[1], finalInputFormat).format(finalValueFormat) : '';
    onChange([start, stop]);
  };

  const handleSimpleChange = (value) => {
    onChange(value ? moment(value, finalInputFormat).format(finalValueFormat) : '');
  };

  const handleUpdatePopper = (data) => {
    const popupElement = data?.instance?.popper;
    if (popupElement instanceof HTMLElement) {
      popperElementRef.current = popupElement;
    }
  };

  const handleCreatePopper = (data) => {
    // handle close on outside click
    handleUpdatePopper(data); // сохраняем текущий popup в ref

    const inputElement = inputRef.current;

    if (popperElementRef.current instanceof HTMLElement && inputElement instanceof HTMLElement) {
      const handleDocumentClick = (event) => {
        if (
          popperElementRef.current.contains(event.target) ||
          event.target === popperElementRef.current ||
          inputElement.contains(event.target) ||
          event.target === inputElement
        ) {
          return;
        }

        document.body.removeEventListener('click', handleDocumentClick);
        setIsOpen(false);
      };

      document.body.addEventListener('click', handleDocumentClick);
    }
  };

  // props
  const commonProps = {
    PopperProps: {
      anchorEl: anchorRef.current,
      popperOptions: { onCreate: handleCreatePopper, onUpdate: handleUpdatePopper },
    },

    renderInput: renderDateFields,

    inputFormat: finalInputFormat,
    mask: finalInputMask,
    open: isOpen,
    value: isRange
      ? [moment(value[0], finalValueFormat), moment(value[1], finalValueFormat)]
      : moment(value, finalValueFormat),

    onOpen: () => setIsOpen(() => true),
    onClose: () => setIsOpen(() => false),
  };

  const dateRangePickerProps = {
    ...commonProps,
    startText: startLabel,
    endText: stopLabel,
    onChange: handleChange,
    ...otherProps,
  };

  const datePickerProps = {
    ...commonProps,
    label: label || startLabel,
    onChange: handleSimpleChange,
    ...otherProps,
  };

  return (
    <div ref={anchorRef} className={cn({ [css.root]: isGrow })}>
      {isRange ? <MDateRangePicker {...dateRangePickerProps} /> : <MDatePicker {...datePickerProps} />}
    </div>
  );
};

DatePicker.propTypes = {
  isGrow: PT.bool,
  wrapperClassName: PT.string,
  className: PT.string,

  inputFormat: PT.string,
  valueFormat: PT.string,
  inputMask: PT.string,

  error: PT.bool,
  isRange: PT.bool,
  label: PT.string,
  size: PT.string,
  startError: PT.bool,
  stopError: PT.bool,
  startLabel: PT.string,
  startWarn: PT.bool,
  stopLabel: PT.string,
  stopWarn: PT.bool,
  warn: PT.bool,
  variant: PT.string,
  value: PT.oneOfType([PT.string, PT.arrayOf(PT.string)]).isRequired,

  onChange: PT.func.isRequired,
};

DatePicker.defaultProps = {
  isGrow: false,
  wrapperClassName: '',
  className: '',

  inputFormat: '',
  valueFormat: '',
  inputMask: '',

  error: false,
  isRange: false,
  label: '',
  size: 'medium',
  startError: false,
  stopError: false,
  startLabel: '',
  stopLabel: '',
  startWarn: false,
  stopWarn: false,
  warn: false,
  variant: 'standard',
};

export default DatePicker;

// modules
import React, { useState } from 'react';

// components
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Slide } from '@material-ui/core';

// hooks
import { useMount } from 'ahooks';

// utils
import cn from 'classnames';

// styles
const useStyles = makeStyles(() => ({
  notification: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0',
    position: 'relative',
  },
  action: {
    cursor: 'pointer',
  },
  connector: {
    position: 'absolute',
    right: 0,
    top: -55,
    borderRight: '3px solid rgb(88, 160, 238)',
    height: 'calc(100% + 56px)',
    width: 0,
    margin: '-1px 0',
  },
  child: {
    marginLeft: 28,
  },
  error: {
    borderColor: 'rgb(228, 98, 82)',
  },
  warning: {
    borderColor: 'rgb(242, 165, 74)',
  },
  info: {
    borderColor: 'rgb(88, 160, 238)',
  },
  success: {
    borderColor: 'rgb(117, 180, 106)',
  },
}));

// static
const TIMEOUT_TO_AUTO_CLOSE_NOTIFICATIONS_IN_MS = 4000;
const TIMEOUT_TO_AUTO_CLOSE_ERRORS_IN_MS = 20000;

const Notification = (props) => {
  const { className, color, nestedNotifications, severity, variant, onRemove } = props;

  const css = useStyles();

  // state
  const [isShowed, setIsShowed] = useState(true);

  // methods
  const handleHide = () => {
    setIsShowed(false);
  };

  // effects
  useMount(() => {
    let timeoutId = null; // auto hide

    const timeoutDelay =
      severity === 'error' ? TIMEOUT_TO_AUTO_CLOSE_ERRORS_IN_MS : TIMEOUT_TO_AUTO_CLOSE_NOTIFICATIONS_IN_MS;

    timeoutId = setTimeout(handleHide, timeoutDelay);

    return () => clearTimeout(timeoutId);
  });

  return (
    <Slide direction="left" in={isShowed} mountOnEnter timeout={400} unmountOnExit onExited={onRemove}>
      <div>
        <div className={cn(css.notification, className)}>
          <Alert
            classes={{
              action: css.action,
            }}
            severity={severity}
            onClose={handleHide}
          >
            {props.children}
          </Alert>
        </div>

        {nestedNotifications?.map?.(({ title }, index) => {
          return (
            <div key={index} className={cn(css.notification, className, css.child)}>
              <div className={cn(css.connector, css[color])} />

              <Alert classes={{ action: css.action }} icon={false} severity={severity} variant={variant}>
                {title}
              </Alert>
            </div>
          );
        })}
      </div>
    </Slide>
  );
};

export default Notification;

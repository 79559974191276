// modules
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// assets
import { EN, RU, DEFAULT_LANGUAGE } from 'assets/constants';
import en from './en';
import ru from './ru';

const resources = {
  [EN]: {
    translation: en,
  },
  [RU]: {
    translation: ru,
  },
};

const lang = localStorage.getItem('lang') || DEFAULT_LANGUAGE;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: lang,
    resources,
    debug: false,
    keySeparator: false,
  });

export default i18n;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/consts';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    width: `100%`,
    listStyle: 'none',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%)',
  },
  active: {},
  activeLink: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
  },
  break: {},
  breakLink: {},
  disabled: {},
  disabledLink: {},
  next: {
    marginLeft: theme.spacing(2),
  },
  nextLink: {
    padding: '6px 16px',
    color: theme.palette.primary.main,
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
    },
    '&$disabledLink': {
      cursor: 'default',
      color: 'gray',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  },
  page: {},
  pageLink: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    outline: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'block',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
    '&$activeLink': {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
  },
  prev: {
    marginRight: theme.spacing(2),
  },
  previousLink: {
    padding: '6px 16px',
    outline: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
    },
    '&$disabledLink': {
      cursor: 'default',
      color: 'gray',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  },
}));

/**
 * props params
 * @param {string} className
 * @param {number} pagesCount
 * @param {({ currentPage: number }) => void} onPageChange
 * @param {number} currentPage
 */

const Paginate = ({ className, pagesCount, onPageChange, currentPage }) => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <td>
      <ReactPaginate
        activeClassName={css.active}
        activeLinkClassName={css.activeLink}
        breakClassName={css.break}
        pageClassName={css.page}
        breakLinkClassName={css.breakLink}
        className={cn(css.root, className)}
        disabledClassName={css.disabled}
        disabledLinkClassName={css.disabledLink}
        nextClassName={css.next}
        nextLinkClassName={css.nextLink}
        pageLinkClassName={css.pageLink}
        previousClassName={css.prev}
        previousLinkClassName={css.previousLink}
        nextLabel={t(i18n.next)}
        previousLabel={t(i18n.prev)}
        breakLabel="..."
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        pageCount={pagesCount}
        forcePage={currentPage - 1}
        onPageChange={onPageChange}
      />
    </td>
  );
};

export default Paginate;

// modules
import React from 'react';

// components
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

// utils
import cn from 'classnames';

// styles
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    padding: theme.spacing(2),
  },

  dialogTitleWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '16px',
    padding: 0,
  },

  emptyDialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: 0,
  },

  dialogTitle: {
    marginTop: theme.spacing(2),
  },

  closeButton: {
    color: theme.palette.grey[500],
  },

  noPaddings: {
    padding: 0,
  },
}));

const CustomDialog = (props) => {
  const {
    isOpen,
    handleClose,

    dialogWrapperClassName,
    titleClassName,
    contentClassName,
    actionsClassName,

    dialogTitleContent,

    title,
    content,
    actions,

    maxWidth = 'md',

    ...otherProps
  } = props;

  const css = useStyles();

  return (
    <Dialog
      classes={{ paper: cn(css.dialogWrapper, dialogWrapperClassName) }}
      disableEnforceFocus
      disableScrollLock
      maxWidth={maxWidth}
      transitionDuration={{
        enter: 0,
        exit: 0,
      }}
      {...otherProps}
      open={isOpen}
      onClose={handleClose}
    >
      <CustomDialogTitle className={titleClassName} content={dialogTitleContent} title={title} onClose={handleClose} />

      <DialogContent className={cn(css.noPaddings, contentClassName)}>{content}</DialogContent>

      {actions && <DialogActions className={cn(css.noPaddings, actionsClassName)}>{actions}</DialogActions>}
    </Dialog>
  );
};

const CustomDialogTitle = (props) => {
  const { className, content, title, onClose } = props;

  const css = useStyles();

  return (
    <DialogTitle
      className={cn(title || content ? css.dialogTitleWrapper : css.emptyDialogTitleWrapper, className)}
      disableTypography
    >
      {content && <>{content}</>}

      {!content && title && (
        <Typography className={css.dialogTitle} variant="h4">
          {title}
        </Typography>
      )}

      <IconButton aria-label="close" className={css.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default CustomDialog;

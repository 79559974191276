// modules
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import { makeStyles, Button, Typography, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';

import { SearchBar, Paginate } from 'components';
import SelectSSP from 'components/SelectSSP';

import { Add as AddIcon } from '@material-ui/icons';

// utils
import { find } from 'lodash';

// i18n
import i18n from 'i18n/consts';

// styles
const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  rootTable: {
    height: '300px !important',
  },

  filtersWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  firstRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  firstRowButtonOnly: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  firstRowFiltersWrapper: {
    display: 'flex',
    gap: '16px',
  },

  secondRow: {
    paddingTop: '16px !important',
    marginBottom: theme.spacing(2),
  },

  createButton: {
    height: 45,
    marginLeft: 'auto',
  },

  select: {
    width: 218,
  },

  addOn: {
    width: 218,
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      footer: {
        display: 'flex',
        '& > td': {
          display: 'flex',
          width: '100%',
          position: 'relative',
          padding: 5,
        },
      },
    },
  },
});

/**
 * props params
 * @param {array} data
 * @param {object} tableProps
 * @param {array} scheme
 * @param {object} paginator
 * @param {(string): void} onOrderChange
 * @param {object} orderBy
 * @param {object?} createButtonProps
 * @param {string?} buttonTitle
 * @param {array?} addOns
 */

const Table = (props) => {
  const {
    addOns,
    buttonTitle,
    createButtonProps,
    data,
    isOneRowTools,
    orderBy,
    paginator,
    search,
    showSearchFilter,
    showOrderFilter,
    tableProps,
    withContainer,
    onOrderChange,
    onSearch,
  } = props;

  const css = useStyles();
  const { t } = useTranslation();

  // constants
  const pageSize = data?.length ?? 0;
  const minPageSize = 2;
  const maxPageSize = 20;

  const buttonSettings = {
    className: css.createButton,
    component: Link,
    color: 'primary',
    variant: 'contained',
    ...(createButtonProps || {}),
  };

  const orderOptions = [
    { label: t(i18n.newFirst), value: 'NEW_FIRST' },
    { label: t(i18n.oldFirst), value: 'OLD_FIRST' },
    { label: t(i18n.sortAZ), value: 'ALPHABETIC' },
  ];

  const orderBySelected = find(orderOptions, ['value', orderBy]);

  const tableToolsProps = {
    addOns,
    buttonSettings,
    buttonTitle,
    showSearchFilter,
    showOrderFilter,
    isOneRowTools,

    orderBySelected,
    orderOptions,
    onOrderChange,

    search,
    onSearch,
  };

  const tableSettingsProps = {
    key: pageSize,
    style: { marginBottom: 20 },
    data,
    localization: {
      header: { actions: '' },
      body: { emptyDataSourceMessage: t(i18n.noData) },
    },
    components: {
      Pagination: (paginateProps) => <Paginate {...paginateProps} {...paginator} />,
    },
    options: {
      pageSize: pageSize > maxPageSize ? maxPageSize : pageSize || minPageSize,
      actionsColumnIndex: 1000,
      search: false,
      headerStyle: {
        backgroundColor: 'whitesmoke',
      },
    },
    ...tableProps,
    title: (
      <Typography color="primary" variant="h5">
        {tableProps?.title || 'Title'}
      </Typography>
    ),
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={withContainer ? css.container : null}>
        <TableTools {...tableToolsProps} />
        <MaterialTable {...tableSettingsProps} />
      </div>
    </MuiThemeProvider>
  );
};

const TableTools = (props) => {
  const {
    addOns,
    buttonSettings,
    buttonTitle,
    showSearchFilter,
    showOrderFilter,
    isOneRowTools,

    search,
    onSearch,

    orderOptions,
    orderBySelected,
    onOrderChange,
  } = props;

  const css = useStyles();
  const { t } = useTranslation();

  let filters = [];

  if (showSearchFilter) {
    filters.push(<SearchBar key="search-filter" className={css.select} searchValue={search} onSearch={onSearch} />);
  }

  if (showOrderFilter) {
    filters.push(
      <SelectSSP
        key="orderBy-filter"
        className={css.select}
        options={orderOptions}
        placeholder={t(i18n.sort)}
        value={orderBySelected}
        onChange={onOrderChange}
      />
    );
  }

  if (addOns) {
    addOns.forEach(({ renderAddOn, position }, idx) => {
      const addOnProps = { className: css.select, key: idx };
      const addOn = renderAddOn(addOnProps);

      if (position >= 0) {
        filters = filters.slice(0, position).concat([addOn, ...filters.slice(position)]);
      } else {
        filters.push(addOn);
      }
    });
  }

  if (!filters.length && !buttonTitle) {
    return null;
  }

  return (
    <div className={css.filtersWrapper}>
      <div className={css.firstRow}>
        <div className={css.firstRowFiltersWrapper}>{filters.slice(0, 4)}</div>

        {buttonTitle && (
          <Button key="create-button" {...buttonSettings}>
            <AddIcon className={css.leftIcon} />
            {buttonTitle}
          </Button>
        )}
      </div>

      {!isOneRowTools && filters.length > 4 && <div className={css.secondRow}>{filters.slice(4)}</div>}
    </div>
  );
};

Table.defaultProps = {
  showSearchFilter: true,
  showOrderFilter: true,
  withContainer: true,
};

export default Table;

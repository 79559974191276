// modules
import React, { useState } from 'react';

// components
import { makeStyles } from '@material-ui/core';
import { DashboardNavBar } from './DashboardNavBar';
import { DashboardTopBar } from './DashboardTopBar';

// graphql
import { useCurrentUser } from 'hooks';

// context
import { ForceUpdateContext } from './DashboardContext';

// utils
import classnames from 'classnames';

// styles
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
  },
  topBar: {
    zIndex: 100,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    zIndex: 3,
    flex: '0 0 auto',
  },
  content: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },
}));

const DashboardLayout = (Component) => (props) => {
  return (
    <Dashboard>
      <Component {...props} />
    </Dashboard>
  );
};

const Dashboard = (props) => {
  const { children } = props;

  const css = useStyles();

  // state
  const [isNavOpen, setNavOpen] = useState(false);
  const [force, setForce] = useState(0);

  // hooks
  const { user } = useCurrentUser();

  // methods
  const handleNavToggle = () => {
    setNavOpen((isOpen) => !isOpen);
  };

  const handleNavClose = () => {
    setNavOpen(false);
  };

  const forceUpdate = () => {
    setForce((f) => f + 1);
  };

  return (
    <div className={css.root}>
      <ForceUpdateContext.Provider value={{ forceUpdate }}>
        <React.Fragment key={force}>
          <DashboardTopBar className={css.topBar} username={user.name} onNavToggle={handleNavToggle} />

          <div id="dashboard-content" className={css.container}>
            <DashboardNavBar className={css.navBar} onClose={handleNavClose} isOpen={isNavOpen} />

            <main className={classnames(css.content, 'page-body')}>{children}</main>
          </div>
        </React.Fragment>
      </ForceUpdateContext.Provider>
    </div>
  );
};

export { Dashboard, DashboardLayout };

// modules
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// components
import { LinearProgress } from '@material-ui/core';

// hooks
import { useCurrentUser } from 'hooks';

const CardLoader = (props) => {
  return <LinearProgress color="primary" variant="indeterminate" {...props} />;
};

const RedirectIfAuthRoute = ({ component: Component, to = '/', ...otherProps }) => {
  const { hasUser, loading } = useCurrentUser();

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (loading) {
          return <CardLoader />;
        }

        if (!hasUser) {
          return <Component {...props} />;
        }

        return <Redirect to={to} />;
      }}
    />
  );
};

export { RedirectIfAuthRoute };

/* eslint-disable react-hooks/exhaustive-deps */

// modules
import React, { useContext, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import { makeStyles, AppBar, IconButton, Toolbar, colors, Menu, Button, MenuItem } from '@material-ui/core';
import SelectSSP from 'components/SelectSSP';
import LanguageSelect from 'components/LanguageSelect';
import { Menu as MenuIcon } from '@material-ui/icons';

// hooks
import { useCurrentUser } from 'hooks';
import { useMutationWithNotification } from 'hooks/customApollo';

// graphql
import { logout as logoutMutation } from 'graphql/requests/auth';

// context
import { ForceUpdateContext } from './DashboardContext';

// utils
import clsx from 'clsx';
import Storage from 'utils/storage';

// assets
import { ACCOUNT_FILTER, roles } from 'assets/constants';

// i18n
import i18n from 'i18n/consts';

const { getStorageItem, setStorageItem } = new Storage(localStorage);

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuBtnContainer: {
    marginLeft: -15,
    marginRight: 5,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLabel: {
    color: 'white',
    fontSize: '1.5rem',
    fontFamily: 'Roboto',
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutBtn: {
    color: 'white',
  },
  logoutMenu: {
    marginTop: 35,
  },
  selectContainer: {
    marginLeft: 150,
    display: 'flex',
    alignItems: 'center',
  },
  selectLabel: {
    color: 'white',
    marginRight: 20,
    fontWeight: 'bold',
    fontSize: 18,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  select: {
    width: 360,
  },
}));

const DashboardTopBar = (props) => {
  const { className, username, onNavToggle, ...rest } = props;
  const { forceUpdate } = useContext(ForceUpdateContext);

  const css = useStyles();
  const { t } = useTranslation();

  // state
  const [openLoginMenu, setLoginMenu] = useState(null);
  const [selectedAccount, setAccount] = useState(false);

  // graphql
  const [logout] = useMutationWithNotification(logoutMutation, {
    shouldNotifyOnSuccess: false,
  });

  // constants
  const { user } = useCurrentUser();

  const { ADMIN_USER } = roles;
  const isShowAccounts = user.role === ADMIN_USER || user.account?.type === 'network';

  // methods
  const handleUsernameClick = (e) => {
    setLoginMenu(e.currentTarget);
  };

  const handleUsernameClose = () => {
    setLoginMenu(null);
  };

  const handleLogout = () => {
    logout().then((res) => {
      if (res.data.logout) {
        window.location.replace('/auth/login');
      }
    });
  };

  // effects
  useEffect(() => {
    const currentAccount = getStorageItem(ACCOUNT_FILTER);
    setAccount(currentAccount || null);
  }, []);

  useEffect(() => {
    const currentAccount = getStorageItem(ACCOUNT_FILTER);

    if (selectedAccount !== false && selectedAccount?.value !== currentAccount?.value) {
      setStorageItem(ACCOUNT_FILTER, selectedAccount);
      forceUpdate();
    }
  }, [selectedAccount]);

  return (
    <AppBar {...rest} className={clsx(css.root, className)} color="primary">
      <Toolbar>
        <IconButton className={css.menuBtnContainer} color="inherit" onClick={onNavToggle}>
          <MenuIcon />
        </IconButton>

        <RouterLink to="/accounts/list">
          <div className={css.logoContainer}>
            <h1 className={css.logoLabel}>OTM SSP</h1>
          </div>
        </RouterLink>

        {isShowAccounts && (
          <div className={css.selectContainer}>
            <div className={css.selectLabel}>{`${t(i18n.account)}: `}</div>

            <SelectSSP
              className={css.select}
              isAsync
              isClearable
              placeholder={t(i18n.chooseAccount)}
              withAccounts
              value={selectedAccount}
              onChange={setAccount}
            />
          </div>
        )}

        <div className={css.flexGrow} />

        <LanguageSelect />

        <Username
          username={username}
          openLoginMenu={openLoginMenu}
          logout={handleLogout}
          handleUsernameClick={handleUsernameClick}
          handleUsernameClose={handleUsernameClose}
        />
      </Toolbar>
    </AppBar>
  );
};

const Username = (props) => {
  const { username, openLoginMenu, handleUsernameClick, handleUsernameClose, logout } = props;

  const css = useStyles();
  const { t } = useTranslation();

  if (!username) {
    return (
      <Button className={css.logoutBtn} onClick={logout}>
        Logout
      </Button>
    );
  }

  return (
    <div>
      <Button className={css.logoutBtn} onClick={handleUsernameClick}>
        {username}
      </Button>

      {username && (
        <Menu
          id="login-menu"
          className={css.logoutMenu}
          anchorEl={openLoginMenu}
          open={!!openLoginMenu}
          onClose={handleUsernameClose}
        >
          <MenuItem onClick={logout}>{t(i18n.logout)}</MenuItem>
        </Menu>
      )}
    </div>
  );
};

export { DashboardTopBar };

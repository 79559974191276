// hooks
import { useQueryWithNotification, useLazyQueryWithNotification } from './customApollo';

// graphql
import { currentUser } from 'graphql/requests/auth';

// static
import { gqlPolicy } from 'assets/constants/graphql';

export const useCurrentUser = () => {
  const { data, loading, error } = useQueryWithNotification(currentUser, {
    errorPolicy: 'all',
    fetchPolicy: gqlPolicy.CACHE_FIRST,
    shouldNotifyOnError: false,
  });

  const hasUser = Boolean(data?.me);
  const user = hasUser ? data.me : {};

  return { hasUser, user, loading, error };
};

export const useCurrentUserLazy = () => {
  const [fetchUser, { data, loading, error }] = useLazyQueryWithNotification(currentUser, {
    errorPolicy: 'all',
    fetchPolicy: gqlPolicy.NETWORK_ONLY,
    shouldNotifyOnError: false,
  });

  const hasUser = Boolean(data?.me);
  const user = hasUser ? data.me : {};

  return { fetchUser, hasUser, user, loading, error };
};

// modules
import React, { useContext, useState, useMemo } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import {
  makeStyles,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/CloseOutlined';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ErrorModal from '../ErrorModal';
import SelectSSP from 'components/SelectSSP';

// context
import { NotificationsContext } from 'components/NotificationsCenter/NotificationsContext';

// utils
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { isEqual } from 'lodash';

// static
import { select, textField, addOn, checkbox, asyncSelect, row } from '../../assets/constants';

// i18n
import i18n from 'i18n/consts';

const useStyles = makeStyles({
  card: {
    margin: 30,
  },
  form: {
    margin: '0 auto',
  },
  inputAdornment: {
    cursor: 'pointer',
  },
  textfieldToolsContainer: {
    position: 'absolute',
    top: 23,
    right: 7,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  textfieldTool: {
    color: 'grey',
    borderRadius: 4,
  },
  textFieldContainer: {
    position: 'relative',
  },
  copiedAlert: {
    position: 'absolute',
    right: 15,
    top: 80,
  },
  padding: {
    padding: '15px 20px',
  },
  select: {
    width: '100%',
  },
  flexRowCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  asyncContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  asyncSelect: {
    width: '100%',
    marginTop: 3,
  },
  asyncLabel: {
    padding: '0 5px',
    color: '#263238',
    fontSize: 11,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    position: 'relative',
    top: 4,
    left: 10,
    zIndex: 1,
    backgroundColor: 'white',
  },
});

/**
 * props params
 * @param {string} title
 * @param {object} defaultFormValues
 * @param {(object) => object}
 * @param {({ form }) => array} schema
 * @param {string} backPath
 * @param {(object) => object} normalizeDataForSubmit
 * @param {array} validateRelations
 * @param {boolean} isCreate
 * @param {graphqRequest} mutation
 * @param {array} optionalItems
 * @param {className} string
 * @param {string | React.Element} saveBtn
 * @param {string | React.Element} cancelBtn
 */
const Form = (props) => {
  const {
    className,
    backPath,
    computeIsSaveDisabled,
    defaultFormValues,
    isCreate,
    isMutationVariablesLikeNormalizedData,
    mutation,
    normalizeDataForSubmit,
    optionalItems,
    postSubmitFunction,
    schema,
    showInvalidModal = true,
    title,
    toValidate,
    validateRelations = [],
    saveBtn,
    cancelBtn,
  } = props;

  const { pushNotification } = useContext(NotificationsContext);

  const css = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const {
    params: { id },
  } = useRouteMatch();

  // state
  const [form, setForm] = useState(defaultFormValues);
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState({});
  const [err, setErr] = useState(null);
  const [invalid, setInvalid] = useState({});

  // methods
  const parseGQLError = (error) => {
    const isErrValid = error && error.graphQLErrors && error.graphQLErrors[0];
    const msg = isErrValid && error.graphQLErrors[0].message;

    const validationFields =
      isErrValid &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.validation &&
      Object.values(error.graphQLErrors[0].extensions.validation);

    return validationFields
      ? `${t(i18n.validationError)}: ${validationFields.join('; ')}`
      : msg || t(i18n.undefinedError);
  };

  const computeVariables = (input) => {
    if (isMutationVariablesLikeNormalizedData) {
      return normalizeDataForSubmit(form);
    }

    if (isCreate) {
      return { input };
    }

    return { id, input };
  };

  const toSubmit = (input) => {
    if (loading) {
      return;
    }

    setLoading(true);

    const variables = computeVariables(input);

    mutation({ variables, fetchPolicy: 'no-cache' })
      .then((response) => {
        setLoading(false);
        if (typeof postSubmitFunction === 'function') {
          postSubmitFunction({ response, data: { ...id, ...input } });
        } else {
          history.push(backPath);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErr(parseGQLError(error));
      });
  };

  const handleChangeHidden = (key) => {
    setHidden({ ...hidden, [key]: !hidden[key] });
  };

  const handleSubmit = () => {
    const invalidItems = toValidate(form);

    if (optionalItems) {
      optionalItems.forEach((i) => delete invalidItems[i]);
    }

    if (Object.values(invalidItems).filter((it) => it).length === 0) {
      toSubmit(normalizeDataForSubmit(form));
    } else {
      const allInvalid = { ...invalidItems };
      setInvalid(allInvalid);

      if (showInvalidModal) {
        const allInvalidStr = Object.keys(allInvalid).join(', ');
        setErr(`${t(i18n.invalidFields)}: ${allInvalidStr}`);
      }
    }
  };

  const handleChange = (e, key, node) => {
    const relatingKeys = Object.keys(validateRelations);
    const relatingValues = Object.values(validateRelations);

    const value = e.target.value;

    if (node) {
      setForm({ ...form, ...node });
      const keys = Object.keys(node);
      const validatedFields = relatingKeys.filter((i) => keys.includes(i));

      if (validatedFields.length) {
        const arrayOfValidation = validatedFields.map((itemKey) => ({ [itemKey]: !node[itemKey] }));
        const newInvalid = arrayOfValidation.reduce((a, b) => ({ ...a, ...b }), {});

        setInvalid({ ...invalid, ...newInvalid });
      }

      return;
    }

    setForm({ ...form, [key]: value });

    if (relatingKeys.length > 0 && (relatingKeys.indexOf(key) !== -1 || relatingValues.indexOf(key) !== -1)) {
      relatingKeys.forEach((relKey, idx) => {
        const isRelatedItems = relKey === key || relatingValues[idx] === key;
        const secondKey = relatingValues[idx] === key ? relKey : relatingValues[idx];

        if (isRelatedItems && Boolean(value)) {
          setInvalid({ ...invalid, [key]: false, [secondKey]: false });
        }
      });
    } else if (value) {
      setInvalid({ ...invalid, [key]: false });
    }
  };

  const handleCheckboxChange = (key) => (_e, value) => {
    setForm({ ...form, [key]: value });
  };

  const handleClearTextfield = (key) => {
    setForm((f) => ({ ...f, [key]: '' }));
  };

  const handleCopyToClipboard = (text) => {
    copy(text);
    pushNotification({ title: t(i18n.copiedToClipboard), variant: 'success' });
  };

  // effects
  const isDirty = useMemo(() => {
    return !isEqual(defaultFormValues, form);
  }, [defaultFormValues, form]);

  // render methods
  const renderSelect = ({
    label,
    value,
    options,
    loading: selectLoading,
    styles,
    disabled,
    customChange,
    getCustomValue,
  }) => (
    <Grid item xs={styles?.fullWidth ? 12 : 6} key={value}>
      <TextField
        error={invalid[value]}
        select
        disabled={disabled}
        margin="normal"
        loading={`${selectLoading !== undefined && !!selectLoading}`}
        className={css.select}
        label={label}
        variant="outlined"
        key={value}
        value={getCustomValue ? getCustomValue(form[value]) : form[value] || ''}
        onChange={(e) => (customChange ? customChange(e, value, handleChange) : handleChange(e, value))}
      >
        {options.map((item) => (
          <MenuItem disabled={!!item.disabled} key={item.id || item} value={item.id || item}>
            {item.name || item}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );

  const renderAsyncSelect = ({
    styles,
    className,
    accountType,
    customChange,
    disabled,
    getCustomValue,
    isClearable,
    label,
    loadOptions,
    value,
    withAccounts,
    ...otherProps
  }) => (
    <Grid key={value} item xs={styles?.fullWidth ? 12 : 6}>
      <SelectSSP
        {...otherProps}
        className={cn(css.asyncSelect, className)}
        styles={{
          control: { border: '1px solid rgb(217, 217, 217)', boxShadow: 'none' },
          singleValue: { color: 'black' },
        }}
        accountType={accountType}
        disabled={disabled}
        error={invalid[value]}
        height={53}
        isAsync
        isClearable={isClearable}
        loadOptions={loadOptions}
        placeholder=""
        selectLabel={label}
        value={getCustomValue ? getCustomValue(form[value]) : form[value] || ''}
        withAccounts={withAccounts}
        onChange={(v) =>
          customChange ? customChange(v, value, handleChange) : handleChange({ target: { value: v } }, value)
        }
      />
    </Grid>
  );

  const renderOneAddOn = (addOnProps, showAddOn, idx, halfWidth) => (
    <Grid item xs={halfWidth ? 6 : 12} key={`add-on-${idx}`}>
      {showAddOn(addOnProps)}
    </Grid>
  );

  const renderTextField = ({
    key,
    name,
    isClearable,
    isHide,
    styles,
    disabled,
    multiline,
    customChange,
    getCustomValue,
    type,
    withCopy,
  }) => {
    const label = name || key[0].toUpperCase().concat(key.slice(1));
    const value = getCustomValue ? getCustomValue(form[key]) : form[key] || '';

    const passwordEye = (isHide || key === 'password') && {
      InputProps: {
        endAdornment: (
          <InputAdornment className={css.inputAdornment} position="end" onClick={() => handleChangeHidden(key)}>
            {hidden[key] ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </InputAdornment>
        ),
      },
    };

    const hideType = isHide ? 'password' : type || 'name';
    const inputType = hidden[key] === true ? 'text' : hideType;

    return (
      <Grid
        key={key}
        className={cn(css.textFieldContainer, styles?.container?.className)}
        item
        xs={styles?.fullWidth ? 12 : 6}
      >
        <TextField
          className={cn(styles?.input?.className)}
          autoComplete="new-password"
          disabled={disabled}
          error={invalid[key]}
          fullWidth
          label={label}
          margin="normal"
          multiline={multiline}
          type={inputType}
          variant="outlined"
          value={value}
          onChange={(e) => (customChange ? customChange(e, key, handleChange) : handleChange(e, key))}
          {...passwordEye}
        />

        {isClearable || withCopy ? (
          <div className={css.textfieldToolsContainer}>
            {isClearable && (
              <IconButton className={css.textfieldTool} onClick={() => handleClearTextfield(key)}>
                <CloseIcon />
              </IconButton>
            )}

            {withCopy && (
              <IconButton className={css.textfieldTool} onClick={() => handleCopyToClipboard(value)}>
                <FilterNoneIcon />
              </IconButton>
            )}
          </div>
        ) : null}
      </Grid>
    );
  };

  const renderCheckbox = ({ key, label, disabled, getCustomValue }) => (
    <div className={css.flexRowCenter} key={key}>
      <Checkbox
        color="primary"
        disabled={disabled}
        checked={Boolean(getCustomValue ? getCustomValue(form[key]) : form[key])}
        onChange={handleCheckboxChange(key)}
      />
      <Typography variant="subtitle1">{label}</Typography>
    </div>
  );

  const renderRow = (items, ridx, controlRenderer) =>
    items.map((item, idx) => {
      const k = `row-${ridx}-${idx}`;
      return (
        <Grid item {...item.column} key={k}>
          {controlRenderer(item, idx)}
        </Grid>
      );
    });

  const renderFormElements = () => {
    const schemaProps = {
      validation: { invalid, setInvalid },
      form: {
        values: form,
        update: ({ key, value, node }) => handleChange({ target: { value } }, key, node),
      },
      renders: { textField: renderTextField, select: renderSelect },
    };

    const controlRenderer = (item, idx) => {
      if (!item) {
        return null;
      }

      const { type, data, renderAddOn, halfWidth } = item;

      switch (type) {
        case select:
          return renderSelect(data);
        case textField:
          return renderTextField(data);
        case checkbox:
          return renderCheckbox(data);
        case addOn:
          return renderOneAddOn(schemaProps, renderAddOn, idx, halfWidth);
        case asyncSelect:
          return renderAsyncSelect(data);
        case row:
          return (
            <Grid container spacing={1} key={`grid-${idx}`}>
              {renderRow(data, idx, controlRenderer)}
            </Grid>
          );
        default:
          return null;
      }
    };
    return schema(schemaProps).map(controlRenderer);
  };

  const renderSaveBtn = () => {
    const defaultBtn = (label) => {
      const isDisabled = loading || !isDirty || computeIsSaveDisabled?.(form);

      return (
        <Button color="primary" disabled={isDisabled} variant="contained" onClick={handleSubmit}>
          {loading === true ? `${t(i18n.saving)}...` : label || t(i18n.save)}
        </Button>
      );
    };

    if (saveBtn) {
      return typeof saveBtn === 'string' ? defaultBtn(saveBtn) : saveBtn(handleSubmit);
    }

    return defaultBtn();
  };

  const renderCanceBtn = () => {
    const defaultBtn = (label) => (
      <Button component={Link} to={backPath} color="primary" disabled={loading}>
        {label || t(i18n.close)}
      </Button>
    );

    if (cancelBtn) {
      return typeof cancelBtn === 'string' ? defaultBtn(cancelBtn) : cancelBtn();
    }

    return defaultBtn();
  };

  return (
    <>
      <Card className={cn(css.card, className)}>
        <CardHeader title={title} />

        <Divider />

        <CardContent>
          <form noValidate autoComplete="off" className={css.form}>
            <Box display="flex" flexDirection="column">
              <Grid container direction="column" spacing={1}>
                {renderFormElements()}
              </Grid>
            </Box>
          </form>
        </CardContent>

        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center" className={css.padding}>
          {renderSaveBtn()}
          {renderCanceBtn()}
        </Box>
      </Card>

      {err && <ErrorModal error={err} onClose={() => setErr(null)} />}
    </>
  );
};

export default Form;

import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const safelyParseJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return {};
  }
};

const initFiltersObj = {};
const resetFiltersObj = {};

const clearFilters = () => {
  localStorage.removeItem('pagesFilters');
};

function initializeFilters(pageName, filtersFromQuery, initFilters = {}) {
  /* query */
  const filtersFromQueryKeys = Object.keys(filtersFromQuery);

  if (filtersFromQueryKeys.length > 0) {
    // всегда добавляем ключи initFilters, чтобы не потерять ключи с "falsy" значениями
    if (Object.keys(initFilters).length > filtersFromQueryKeys.length) {
      return { ...initFilters, ...filtersFromQuery };
    }

    return filtersFromQuery;
  }

  /* localStorage */
  const stringifiedPagesFiltersFromLocalStorage = localStorage.getItem('pagesFilters');
  const pagesFiltersFromLocalStorage = safelyParseJSON(stringifiedPagesFiltersFromLocalStorage) ?? {};
  const currentFiltersFromLocalStorage = pagesFiltersFromLocalStorage[pageName] ?? {};

  if (Object.keys(currentFiltersFromLocalStorage).length > 0) {
    return currentFiltersFromLocalStorage;
  }

  /* initFilters */
  return initFilters;
}

/**
 * Как работает хук useFilters:
 * 1. инициализирует фильтры с помощью initializeFilters()
 * 2. сохраняет фильтры в localStorage и query
 * 3. возвращает filters, handleChangeFilter, handleApplyFilters, handleResetFilters
 *
 * !!! в defaultFilters передавать только объект не меняющий ссылку при каждом рендеринге (для того чтобы избежать infinite loop):
 * - объект из глобальной области видимости
 * - мемоизированный объект
 * - объект в виде state
 *
 * Этап 1 "инициализация фильтров" | приоритет инициализации фильтров:
 * 1. query
 * 2. localStorage
 * 3. параметры по умолчанию на странице
 *
 * Этап 2 "сохранение фильтров после инициализации" | после инициализации фильтров сохранить фильтры в:
 * - localStorage
 * - query
 *
 * Этап 3 "изменение фильтров":
 * - при изменении фильтров использовать метод handleChangeFilter
 * - при "сбросе" фильтров использовать метод handleResetFilters
 * - при "применении" фильтров вызывать метод handleApplyFilters (возможно необходимо создать новую обертку для setInteractions)
 *
 * @param pageName
 * @param initFilters
 * @param resetFilters
 * @returns {{handleApplyFilters: handleApplyFilters, handleResetFilters: handleResetFilters, handleChangeFilter: (function(*): function(*): void), filters: (*|{}|{})}}
 */
const useFilters = (pageName, initFilters = initFiltersObj, resetFilters = resetFiltersObj) => {
  const location = useLocation();
  const history = useHistory();

  // state
  const [filters, setFilters] = useState(() => {
    const locationSearch = location.search ?? '';
    const filtersFromQuery = queryString.parse(locationSearch);
    return initializeFilters(pageName, filtersFromQuery, initFilters);
  });

  const saveFiltersToLocalStorageAndQuery = (filters) => {
    /* localStorage */
    const stringifiedPagesFiltersFromLocalStorage = localStorage.getItem('pagesFilters');
    const pagesFiltersFromLocalStorage = safelyParseJSON(stringifiedPagesFiltersFromLocalStorage) ?? {};

    const pagesFiltersForLocalStorage = { ...pagesFiltersFromLocalStorage, [pageName]: filters };
    const stringifiedPagesFiltersForLocalStorage = JSON.stringify(pagesFiltersForLocalStorage);
    localStorage.setItem('pagesFilters', stringifiedPagesFiltersForLocalStorage);

    /* query */
    const newSearchString = queryString.stringify(filters, { skipEmptyString: true, skipNull: true });

    if (newSearchString?.replace('?', '') !== history.location.search?.replace?.(`?`, ``)) {
      history.replace({ search: newSearchString });
    }
  };

  const handleChangeFilter = (filterName) => (value) => {
    setFilters((f) => ({ ...f, [filterName]: value }));
  };

  const handleApplyFilters = useCallback(() => {
    saveFiltersToLocalStorageAndQuery(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleResetFilters = () => {
    setFilters(resetFilters);
  };

  // effects
  useEffect(() => {
    if (!pageName || typeof initFilters !== 'object') {
      return;
    }

    const locationSearch = location.search ?? '';
    const filtersFromQuery = queryString.parse(locationSearch);
    const initialFilters = initializeFilters(pageName, filtersFromQuery, initFilters);

    setFilters(initialFilters);
    saveFiltersToLocalStorageAndQuery(initialFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, initFilters]);

  return { filters, handleChangeFilter, handleApplyFilters, handleResetFilters };
};

export { clearFilters, useFilters };

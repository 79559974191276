import { useMemo } from 'react';

import i18n from 'i18next';

import { isEqual } from 'lodash';

export const INTEGER = 'INTEGER';
export const FLOAT = 'FLOAT';
export const PERCENT = 'PERCENT';
export const CURRENCY = 'CURRENCY';
export const MONEY = 'MONEY';

// если в formatValue передается number_format === null => форматируем значение по умолчанию с помощью formatObj
// если в formatValue передается number_format !== null => форматируем значение с помощью нового форматтера

const useFormatNumber = () => {
  const locale = i18n.language;

  const formatObj = useMemo(
    () => ({
      [INTEGER]: {
        options: { locale, number_format: null },
        formatter: new Intl.NumberFormat(locale, { style: 'decimal' }),
      },

      [FLOAT]: {
        options: { locale, number_format: null },
        formatter: new Intl.NumberFormat(locale, { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
      },

      [PERCENT]: {
        options: { locale, number_format: null },
        formatter: new Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'percent',
        }),
      },

      [CURRENCY]: {
        options: { locale, number_format: null },
        formatter: new Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          currency: 'RUB',
          style: 'currency',
        }),
      },

      [MONEY]: {
        options: { locale, number_format: null },
        formatter: new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formatNumber = (type, value, number_format) => {
    if (isEqual({ locale, number_format }, formatObj[type].options)) {
      if (type === PERCENT) {
        return formatObj[type].formatter.format(value / 100);
      }

      return formatObj[type].formatter.format(value);
    }

    if (type === INTEGER) {
      return new Intl.NumberFormat(locale, { ...number_format, style: 'decimal' }).format(value);
    }

    if (type === FLOAT) {
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
        ...number_format,
      }).format(value);
    }

    if (type === PERCENT) {
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...number_format,
        style: 'percent',
      }).format(value / 100);
    }

    if (type === CURRENCY) {
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'RUB',
        ...number_format,
        style: 'currency',
      }).format(value);
    }

    if (type === MONEY) {
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...number_format,
      }).format(value);
    }

    return value;
  };

  return { formatNumber };
};

export { useFormatNumber };

export default class Storage {
  constructor(customStorage) {
    this.storage = customStorage || localStorage;
  }

  /**
   *
   * @param {string} item
   * @returns {void}
   */
  setStorageItem = (key, itemValue) => {
    try {
      const value = JSON.stringify(itemValue || '');
      this.storage.setItem(key, value);
    } catch (e) {
      this.storage.setItem(key, '');
    }
  };

  /**
   *
   * @param {string} key
   * @returns {string}
   */
  getStorageItem = (key) => {
    const value = this.storage.getItem(key);
    try {
      if (value) return JSON.parse(value);
      return '';
    } catch (e) {
      return '';
    }
  };

  /**
   * @param {string[]} itemsToClear
   * @returns {void}
   */
  clearStorage = (itemsToClear) => {
    if (itemsToClear.length) itemsToClear.forEach((key) => this.setStorageItem(key));
    else this.storage.clear();
  };
}

// modules
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/react-hooks';

// providers
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { LocalizationProvider } from '@material-ui/pickers';

// components
import App from './App';
import { LinearProgress } from '@material-ui/core';

// utils
import MomentUtils from '@material-ui/pickers/adapter/moment';

// configs
import theme from './theme';
import { client } from 'graphql/apollo/client';
import { history } from './routing/history';
import { configureStore } from './store';
import { configService } from './configService';

// mixins
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';
import './i18n';
import { yandexMetrics } from 'components/YandexMetrics';

configService
  .init()
  .catch(() => {
    // catch only configService.init(). Other exceptions catched by Sentry and boundaries
    document.body.innerHTML = `
<style>body{display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;}</style>
<div style="text-align: center">Ошибка инициализации приложения. Обратитесь к администратору.<br/>
Cannot initialize application. Contact the administrator.<br/>
<a href="#" onclick="document.location.reload()">Перезагрузить страницу / Reload App</a> </div>
`;
  })
  .then(() => {
    const rootContainer = document.getElementById('root');

    if (rootContainer) {
      const store = configureStore();

      ReactDOM.render(
        <ApolloProvider client={client}>
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

          <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<LinearProgress color="primary" />}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <Router history={history}>
                    <yandexMetrics.Init />
                    <App />
                  </Router>
                </LocalizationProvider>
              </Suspense>
            </ThemeProvider>
          </StoreProvider>
        </ApolloProvider>,
        rootContainer
      );
    }
  });

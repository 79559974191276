// Modules
import React, { useState } from 'react';
import { withStyles, IconButton, Dialog, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

// i18n
import i18n from 'i18n/consts';

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'relative',
    bottom: 10,
    left: 10,
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

/**
 * props params
 * @param {() => void} onClose
 * @param {string} error
 */

function ErrorModal({ error, onClose }) {
  const [errMsg, setErr] = useState(error);
  const { t } = useTranslation();

  function handleClose() {
    onClose ? onClose() : setErr(null);
  }

  return (
    <Dialog onClose={handleClose} open={errMsg !== null}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t(i18n.fail)}
      </DialogTitle>
      <DialogContent>
        <Typography>{errMsg}</Typography>
      </DialogContent>
    </Dialog>
  );
}

export default ErrorModal;

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQueryWithNotification } from 'hooks/customApollo';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';
// Components
import { Table, Boundary } from 'components';

// Assets
import { ACCOUNT_FILTER, paginatorPageLength } from '../../assets/constants';

// Graphql
import { gqlPolicy } from 'assets/constants/graphql';
// i18n
import i18n from 'i18n/consts';
import Storage from 'utils/storage';

const { getStorageItem } = new Storage(localStorage);

/**
 * props params
 * @param {function} parseData
 * @param {string} entity
 * @param {object} tableProps
 * @param {string} label
 * @param {string} createLabel
 * @param {array} scheme
 * @param {graphqlRequest} query
 * @param {object?} queryParams
 * @param {array?} addOns
 * @param {object} createButtonProps
 */

function List(props) {
  // props
  const {
    parseData,
    entity,
    query,
    tableProps,
    label,
    scheme,
    addOns,
    queryParams,
    createButtonProps,
    createLabel,
    isOneRowTools,
  } = props;
  // hooks
  const history = useHistory();
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const [items, setItems] = useState();
  const [orderBy, setOrderBy] = useState('NEW_FIRST');
  const [fetchData, { data, loading, error }] = useLazyQueryWithNotification(query, {
    fetchPolicy: gqlPolicy.NETWORK_ONLY,
    variables: { ...queryParams },
  });
  const throttledFetchData = throttle((queryProps) => fetchData(queryProps), 600, { trailing: false });
  const currentAccount = getStorageItem(ACCOUNT_FILTER);
  const [search, setSearch] = useState('');
  const [searchTimeOut, setSearchTimeout] = useState();

  const handleSearchChange = () => {
    searchTimeOut && clearTimeout(searchTimeOut);
    setSearchTimeout(
      setTimeout(() => {
        if (!isMounted.current) return;
        throttledFetchData({
          variables: {
            orderBy,
            search,
            using_account: currentAccount?.value,
          },
        });
      }, 500)
    );
  };

  // eslint-disable-next-line
  useEffect(() => throttledFetchData({ variables: { orderBy: orderBy, search, using_account: currentAccount?.value } }), [orderBy]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSearchChange(), [search]);
  useEffect(() => data && setItems(data), [data]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const actions = tableProps.actions
    ? [
        {
          icon: 'edit',
          iconProps: { color: 'primary' },
          tooltip: `${t(i18n.edit)} ${label}`,
          onClick: (_e, row) => history.push(`/${entity}/${row.id}/edit`),
        },
        ...tableProps.actions,
      ]
    : [];

  const onPageChange = ({ selected }) => {
    throttledFetchData({
      variables: {
        page: selected + 1,
        orderBy,
        search,
        ...(currentAccount && { using_account: currentAccount.value }),
      },
    });
  };

  const handleOrderChange = (order) => {
    setOrderBy(order.value);
  };

  const paginator = {
    pagesCount: items && Math.ceil(items.items.paginatorInfo.total / paginatorPageLength),
    onPageChange,
    currentPage: items && items.items.paginatorInfo.currentPage,
  };

  const createBtnProps = createButtonProps || { to: `/${entity}/create` };

  return (
    <Boundary data={data || items} loading={loading} error={error}>
      <Table
        addOns={addOns}
        isOneRowTools={isOneRowTools}
        onOrderChange={handleOrderChange}
        orderBy={orderBy}
        onSearch={setSearch}
        search={search}
        data={parseData(items)}
        tableProps={{ ...tableProps, actions }}
        scheme={scheme}
        paginator={paginator}
        page={items && items.items.paginatorInfo.currentPage}
        {...(createLabel && { buttonTitle: createLabel, createButtonProps: createBtnProps })}
      />
    </Boundary>
  );
}

export default List;

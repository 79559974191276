import validate from 'validate.js';

const regex = (value, options) => {
  const regExp = new RegExp(options.pattern);
  return !regExp.test(value) && options.message;
};

const checked = (value, options) => value !== true && (options.message || 'must be checked');

validate.validators = {
  ...validate.validators,
  regex,
  checked,
};

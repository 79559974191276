import React from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';

const YM_ID = 70337674;

const options = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
};

const isProduction = process.env.NODE_ENV === 'production';

export const yandexMetrics = {
  Init: () => (isProduction ? <YMInitializer accounts={[YM_ID]} options={options} /> : null),
  sendRegistration: () => ym('reachGoal', 'reg-confirm'),
};

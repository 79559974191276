// element types (form component)
const textField = 'text-field';
const select = 'select';
const asyncSelect = 'async-select';
const addOn = 'add-on';
const checkbox = 'checkbox';
const row = 'row';

// table pagination
const paginatorPageLength = 20;

// time
const WEEK = 604800000;

// global OTM roles
const roles = Object.freeze({
  ADMIN_USER: 'ADMIN_USER',
  NETWORK_MAIN_USER: 'NETWORK_MAIN_USER',
  NORMAL_MAIN_USER: 'NORMAL_MAIN_USER',
  NORMAL_USER: 'NORMAL_USER',
});

// unit types
const unitTypes = Object.freeze({
  BANNER: 'banner',
  VIDEO_INSTREAM: 'video_instream',
  VIDEO_OUTSTREAM: 'video_outstream',
  VIDEO_FLYROLL: 'video_flyroll',
  AUDIO_INSTREAM: 'audio_instream',
});

// storage
const SITE_FILTER = 'site-filter';
const ACCOUNT_FILTER = 'account-filter';
const LANGUAGE = 'language';
const REPORT = 'report';

// status
const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';
const TEST = 'TEST';

// passbackStrategy
const HIDE = 'HIDE';
const REPEAT = 'REPEAT';
const CUSTOM = 'CUSTOM';

// language
const EN = 'en';
const RU = 'ru';
const DEFAULT_LANGUAGE = RU;

export {
  textField,
  select,
  asyncSelect,
  addOn,
  row,
  checkbox,
  roles,
  unitTypes,
  paginatorPageLength,
  WEEK,
  SITE_FILTER,
  ACCOUNT_FILTER,
  LANGUAGE,
  REPORT,
  ACTIVE,
  INACTIVE,
  TEST,
  HIDE,
  REPEAT,
  CUSTOM,
  EN,
  RU,
  DEFAULT_LANGUAGE,
};

import c from './consts';

const ru = Object.freeze({
  [c.update]: 'Обновить',
  [c.backToHome]: 'Домой',
  [c.logout]: 'Выйти',
  [c.serverError]: 'Ошибка сервера',
  [c.data]: 'Данные',
  [c.saved]: 'Сохранено',
  [c.saved_plural]: 'Сохранены',
  [c.unknown]: 'Неизвестный',
  [c.unknown_she]: 'Неизвестная',
  [c.error]: 'Ошибка',
  [c.copiedToClipboard]: 'Скопировано в буфер обмена',
  [c.delete]: 'Удалить',
  [c.apply]: 'Применить',
  [c.parameters]: 'Параметры',

  [c.warnings]: 'Предупреждения',
  [c.errors]: 'Ошибки',
  [c.noErrors]: 'Нет ошибок',
  [c.request]: 'Запрос',
  [c.response]: 'Ответ сервера',

  [c.policy]: 'Политика',
  [c.invalidFields]: 'Недопустимые поля',

  [c.logInHeader]: 'Вход в личный кабинет',
  [c.logIn]: 'Войти',
  [c.loggingIn]: 'Вход',
  [c.failedLogIn]: 'Вход не выполнен',
  [c.password]: 'Пароль',
  [c.forgotPassword]: 'Забыли пароль',
  [c.register]: 'Регистрация',
  [c.registration]: 'Регистрация',
  [c.emailConfirmation]: 'Подтверждение Email',
  [c.registerSuccess]:
    'Регистрация прошла успешно! Мы отправили вам на почту ссылку - перейдите по ней, чтобы начаться пользоваться системой',
  [c.confirmSuccess]:
    'Ваш адрес электронной почты успешно подтвежден. Вы можете вернуться на страницу входа, чтобы начать пользоваться нашим сервисом',
  [c.fail]: 'Произошла ошибка. Пожалуйста, повторите позже',
  [c.changePassSuccess]: 'Ваш пароль успешно изменён. Вы можете вернуться на страницу входа чтобы войти в систему',
  [c.verificateAgain]:
    'Ваш E-mail не подтвержден. Пожалуйста, проверьте ваш почтовый ящик. Вы также можете повторно отправить письмо с подтверждением.',
  [c.agreePolicy]: 'Я  соглашаюсь с <0>политикой обработки персональных данных</0>',
  [c.sendAgain]: 'Отправить повторно',
  [c.mailSent]: 'Письмо успешно отправлено',
  [c.verificateSent]: 'Письмо с подтверждением успешно отправлено',
  [c.wrongCredentials]: 'Неверный email или пароль',
  [c.restorePassword]: 'Восстановить пароль',
  [c.resetPassword]: 'Восстановить пароль',
  [c.reset]: 'Восстановить',
  [c.restore]: 'Восстановить',
  [c.restoreSuccess]: '',
  [c.enterEmail]: 'Укажите адрес электронной почты',
  [c.goBack]: 'Вернуться',
  [c.accounts]: 'Аккаунты',
  [c.account]: 'Аккаунт',
  [c.dsp]: 'DSP',
  [c.ssp]: 'SSP',
  [c.supplyManager]: 'Менеджер по снабжению',
  [c.hostAccount]: 'Хост-аккаунт',
  [c.chooseAccount]: 'Выбрать аккаунт',
  [c.accountType]: 'Тип аккаунта',
  [c.accountName]: 'Название аккаунта',
  [c.counterparty]: 'Контрагент',
  [c.accountCommission]: 'Комиссия аккаунта',
  [c.accountUsers]: 'Пользователи аккаунта',
  [c.noAccountUsers]: 'У аккаунта нет пользователей',
  [c.accountDomain]: 'Домен аккаунта',
  [c.legalEntity]: 'Юрлицо',
  [c.paymentInfo]: 'Платежная информация',
  [c.chooseAsMain]: 'Сделать главным',
  [c.domain]: 'Доменное имя',
  [c.trackingUrl]: 'URL трекинга показов',
  [c.chooseLogo]: 'Выбрать логотип',
  [c.network]: 'Сетевой',
  [c.local]: 'Локальный',
  [c.users]: 'Пользователи',
  [c.user]: 'Пользователь',
  [c.userRus]: 'Пользователя',
  [c.fullName]: 'Полное имя',
  [c.readOnly]: 'Только для чтения',
  [c.adminPermissions]: 'Права администратора',
  [c.newPassword]: 'Новый пароль',
  [c.passwordConfirmation]: 'Повторите пароль',
  [c.sendInvitation]: 'Отправить приглашение',
  [c.sites]: 'Сайты',
  [c.site]: 'Сайт',
  [c.siteName]: 'Название сайта',
  [c.siteUrl]: 'URL сайта',
  [c.defaultMinCPM]: 'Min. CPM по-умолчанию',
  [c.units]: 'Рекламные блоки',
  [c.unit]: 'Рекламный блок',
  [c.shortUnit]: 'Блок',
  [c.unitName]: 'Название рекламного блока',
  [c.size]: 'Размер',
  [c.height]: 'Высота',
  [c.width]: 'Ширина',
  [c.responsive]: 'Адаптивный',
  [c.htmlCodePlug]: 'Код заглушки (HTML)',
  [c.hideStrategy]: 'Скрыть рекламный слот при отсутствии рекламы',
  [c.repeatStrategy]: 'Запрашивать рекламу повторно',
  [c.customStrategy]: 'Ручная настройка заглушки',
  [c.wrongHtmlStub]: 'Поле обязательно для заполнения',
  [c.inheritSiteSettings]: 'Наследовать настройки сайта',
  [c.setupCode]: 'Код установки',
  [c.redact]: 'Редактировать',
  [c.close]: 'Закрыть',
  [c.minCpm]: 'Мин. CPM',
  [c.maxDuration]: 'Макс. длителность',
  [c.vastPlugUrl]: 'URL-адрес VAST-заглушки',
  [c.wrongVastUrl]: 'Некорректный URL-адрес VAST-заглушки',
  [c.chooseUnitType]: 'Выберите тип рекламного блока',
  [c.type]: 'Тип',
  [c.siteType]: 'Тип сайта',
  [c.bannerDesc]: 'Простой прямоугольный баннер, для которого вы можете задать произвольный размер.',
  [c.videoInstreamDesc]:
    'Подходит для монетизации уже размещенных на сайте видео-материалов. Реклама выдается в формате VAST.',
  [c.videoOutstreamDesc]:
    'Прямоугольный баннер большого размера, который содержит видео-плеер с рекламой. Видео-реклама запускается автоматически при попадании плеера в поле видимости.',
  [c.videoFlyrollDesc]:
    'Outstream-размещения (видеореклама в теле статьи) без привязки плеера к контенту. При скроллинге плеер не пропадает из зоны видимости, а миниатюрой перемещается в нижнюю часть экрана, продолжая просмотр ролика',
  [c.audioInstream]: 'Подходит для монетизации размещенных на сайте аудио-материалов. Реклама выдается в формате VAST.',
  [c.statusActive]: 'Активные',
  [c.statusInactive]: 'Неактивные',
  [c.statusTest]: 'Тестовые',
  [c.availableVastTagParameters]: 'Дополнительные параметры VAST Tag',
  [c.blockedAdvertiserDomains]: 'Блокировка доменов рекламодателей',
  [c.clearAll]: 'Очистить всё',
  [c.showColumns]: 'Показать колонки',
  [c.noData]: 'Нет записей',
  [c.name]: 'Имя',
  [c.entityName]: 'Название',
  [c.active]: 'Активный',
  [c.inactive]: 'Неактивный',
  [c.create]: 'Создать',
  [c.createNew]: 'Создать новый',
  [c.createNewRus]: 'Создать нового',
  [c.edit]: 'Изменить',
  [c.search]: 'Поиск',
  [c.prev]: 'Предыдущая',
  [c.next]: 'Следущая',
  [c.status]: 'Статус',
  [c.placement]: 'Размещение',
  [c.inStream]: 'In-Stream',
  [c.inBanner]: 'Видеореклама в баннерах',
  [c.inArticle]: 'В статьях',
  [c.inFeed]: 'В фиде',
  [c.interstitial]: 'Фулскрины/Slider/Fly-Roll',
  [c.newFirst]: 'Сначала новые',
  [c.webSite]: 'Веб-сайт',
  [c.app]: 'Приложение',
  [c.oldFirst]: 'Сначала старые',
  [c.sortAZ]: 'По алфавиту',
  [c.creationDate]: 'Дата создания',
  [c.updateDate]: 'Дата изменения',
  [c.registrationDate]: 'Дата регистрации',
  [c.sort]: 'Сортировать',
  [c.yes]: 'Да',
  [c.no]: 'Нет',
  [c.validationError]: 'Ошибка валидации',
  [c.undefinedError]: 'Неизвестная ошибка',
  [c.new]: 'Новый',
  [c.language]: 'Язык',
  [c.reports]: 'Конструктор отчётов',
  [c.format]: 'Формат',
  [c.save]: 'Сохранить',
  [c.saving]: 'Сохранение',
  [c.cancel]: 'Отмена',
  [c.preview]: 'Просмотр',
  [c.refresh]: 'Обновить',
  [c.dated]: 'От',
  [c.withoutNumShort]: 'б/н',

  [c.noOptions]: 'Нет опций',
  [c.typeAtLeastCharsCount_one]: 'Введите минимум {{count}} символ',
  [c.typeAtLeastCharsCount_few]: 'Введите минимум {{fewCount}} символа',
  [c.typeAtLeastCharsCount_many]: 'Введите минимум {{manyCount}} символов',

  // error pages
  [c.errorTitle401]: 'Нам очень жаль, но мы не можем вас авторизовать',
  [c.errorTitle404]: 'Такой страницы не существует',
  [c.errorTitle500]: 'Что-то пошло не так',

  // ord
  [c.ord]: 'ОРД',
  [c.contracts]: 'Договоры',
  [c.invoices]: 'Акты',
  [c.import]: 'Импорт',
  [c.export]: 'Экспорт',
  [c.newExport]: 'Новый экспорт',
  [c.exports]: 'Выгрузки',
  [c.noAvailablePeriods]: 'Нет доступных периодов',
  [c.tokens]: 'Токены',

  [c.warningsAndErrors]: 'Предупреждения и ошибки',

  // ord contracts
  [c.createNewContract]: 'Создать новый договор',
  [c.editContract]: 'Редактировать договор',

  // ord invoices
  [c.amountOf]: 'Кол-во {{entity}}',
  [c.invoicesUploaded]: 'Загружено актов',
  [c.checking]: 'На проверке',
  [c.readyToSend]: 'Готов к отправке',
  [c.wereSentToOrd]: 'Отправлено в ОРД',

  [c.uploadInvoicesFrom1c]: 'Загрузить акты из 1C',
  [c.uploadHistory]: 'История загрузок',
  [c.fileSuccessfullyUploaded]: 'Файл успешно загружен',
  [c.errorDuringFileUpload]: 'Ошибка при загрузке файла',
  [c.uploadErids]: '+ Загрузить',
  [c.file]: 'Файл',
  [c.filePath]: 'Путь файла',
  [c.uploaded]: 'Загружено',
  [c.updateOtmDspErids]: 'Загрузить данные OTM DSP',

  [c.eridSource]: 'Источник',
  [c.EridExportReportSourceEnum]: {
    OTM_EXCHANGE: 'ОТМ Exchange',
    OTM_DSP: 'ОТМ DSP',
  },

  [c.fileUploadErrorId]: 'Ошибка загрузки файла #{{fileId}}',
  [c.exception]: 'Исключение',
  [c.logs]: 'Логи',

  [c.counterpartyType]: 'Тип контрагента',
  [c.invoiceNumber]: '№ акта',
  [c.invoiceDate]: 'Дата акта',
  [c.invoicePeriod]: 'Период акта',
  [c.invoiceStatus]: 'Статус акта',
  [c.contract]: 'Договор',
  [c.invoiceAmount]: 'Сумма акта',
  [c.invoiceVATAmount]: 'Сумма НДС',
  [c.invoiceWithoutVATAmount]: 'Сумма акта без НДС',
  [c.includesVAT]: 'Включает НДС',
  [c.statAmount]: 'Начисления по статистике',
  [c.invoicesTotalWithoutVAT]: 'Сумма актов без НДС',
  [c.erirStatus]: 'Статус ЕРИР',
  [c.confirmDeleteInvoice]: 'Вы действительно хотите удалить этот акт ({{- description}})?',
  [c.confirmDeleteInvoiceRow]: 'Вы действительно хотите удалить строку акта ({{- description}}) с суммой {{sum}}?',
  [c.customer]: 'Заказчик',
  [c.customerRole]: 'Роль заказчика',
  [c.executor]: 'Исполнитель',
  [c.executorRole]: 'Роль исполнителя',

  [c.numberAndDateOfInitialContract]: 'Номер / Дата изначального договора',
  [c.amountLinkedToOriginalContract]: 'Сумма в привязке к изначальному договору',

  [c.temporaryInvoice]: 'Временный акт',
  [c.createTemporaryInvoiceForId]: 'Создание временного акта для ID #{{id}}',

  [c.modifyInvoice]: 'Изменить акт',
  [c.startOfReportingPeriod]: 'Начало отчетного периода',
  [c.endOfReportingPeriod]: 'Конец отчетного периода',

  [c.selectExistingContract]: 'Выберите существующий договор',
  [c.orCreateTempInvoiceBasedOnNewContract]: 'Или создайте временный акт на основе нового договора',

  [c.existingContract]: 'Существующий договор',
  [c.newContract]: 'Новый договор',

  [c.contractNumber]: 'Номер договора',
  [c.contractDate]: 'Дата договора',
  [c.clientType]: 'Тип клиента',
  [c.client]: 'Клиент',
  [c.ordId]: 'ОРД ID',

  [c.INVOICE_STATUS_DRAFT]: 'Черновик',
  [c.INVOICE_STATUS_QUEUED]: 'Запланирован для процессинга',
  [c.INVOICE_STATUS_PROCESSING]: 'Выполняется процессинг',
  [c.INVOICE_STATUS_PROCESSED]: 'Процессинг завершен',
  [c.INVOICE_STATUS_READY]: 'Готов к отправке в ОРД',
  [c.INVOICE_STATUS_NOT_READY]: 'Не готов к отправке в ОРД',
  [c.INVOICE_STATUS_SENDING]: 'Отправляется в ОРД',
  [c.INVOICE_STATUS_SENT]: 'Отправлен в ОРД',
  [c.INVOICE_STATUS_ERROR]: 'Не готов к отправке в ОРД',

  [c.INVOICE_ROLE_AGENCY]: 'Агентство',
  [c.INVOICE_ROLE_DISTRIBUTOR]: 'Распространитель',
  [c.INVOICE_ROLE_OPERATOR]: 'Оператор рекламной системы',
  [c.INVOICE_ROLE_ADVERTISER]: 'Рекламодатель',

  [c.ERIR_STATUS_WAIT]: 'Принято к отправке',
  [c.ERIR_STATUS_REVIEW]: 'На ревью',
  [c.ERIR_STATUS_ACCEPTED]: 'Принято',
  [c.ERIR_STATUS_ERROR]: 'Ошибка',

  [c.NEW]: 'Новый',
  [c.PROCESS]: 'В процессе',
  [c.SUCCESS]: 'Успешно',
  [c.ERROR]: 'Ошибка',

  [c.invoiceActionProcess]: 'Запланировать для процессинга',
  [c.invoiceActionSend]: 'Отправить в ОРД',
  [c.invoiceActionDraft]: 'Перевести в Черновик',
  [c.invoiceActionOpenReport]: 'Открыть отчет по акту',

  [c.invoiceWasRemoved]: 'Акт удален',
  [c.rowWasRemoved]: 'Строка удалена',

  [c.sheet]: 'Лист',
  [c.row]: 'Строка',

  // users
  [c.userStatus]: 'Статус пользователя',

  // paments
  [c.paymentsMenuLabel]: 'Выплаты',
  [c.year]: 'Год',
  [c.month]: 'Месяц',
  [c.January]: 'Январь',
  [c.February]: 'Февраль',
  [c.March]: 'Март',
  [c.April]: 'Апрель',
  [c.May]: 'Май',
  [c.June]: 'Июнь',
  [c.July]: 'Июль',
  [c.August]: 'Август',
  [c.September]: 'Сентябрь',
  [c.October]: 'Октябрь',
  [c.November]: 'Ноябрь',
  [c.December]: 'Декабрь',
  [c.impressions]: 'Показы',
  [c.supplyRevenue]: 'Доходы поставщиков',
  [c.dspPayments]: 'Выплаты DSP',
  [c.paymentsMargin]: 'Маржа',
  [c.payoutDiff]: 'Разница выплат (DSP)',
  [c.paymentsClosed]: 'Статус',
  [c.report]: 'Отчет',
  [c.total]: 'Итого',
  [c.revenue]: 'Доходы',
  [c.finalize]: 'Финализировать',
  [c.download]: 'Скачать',
  [c.supplier]: 'Поставщик',
  [c.payoutDiffSupplier]: 'Разница выплат (Supplier)',
  [c.isSspInvoice]: 'Закрытие общим актом с SSP',
});

export default ru;

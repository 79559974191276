// Modules
import React from 'react';
import { LinearProgress, Paper, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
// Components
import ErrorModal from '../ErrorModal';

function noData(label, classes) {
  return (
    <Paper>
      <Box display="flex" justifyContent="center" alignItems="center" className={classes.noData}>
        <Typography variant="h4">{label || 'Data did not recieved'}</Typography>
      </Box>
    </Paper>
  );
}

/**
 * props params
 * @param {string?} warn
 * @param {boolean} error
 * @param {boolean} loading
 * @param {boolean} data
 * @param {ReactChildren} children
 * @param {string?} where
 */
class Boundary extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      globalError: null,
    };
  }

  static getDerivedStateFromError(e) {
    return { globalError: e || 'Undefined error. We will fix it soon' };
  }

  render() {
    const { error, loading, data, children, where, classes, warn } = this.props;
    const { globalError } = this.state;
    const gqlMessage = error && error.graphQLErrors?.[0] && error.graphQLErrors[0]?.message;

    if (globalError || error) return <ErrorModal error={globalError || gqlMessage || 'Undefined server error'} />;
    if (loading && !data) return <LinearProgress color="secondary" className={classes.linear} />;
    if (!data) return noData(where, classes);
    return (
      <>
        {children}
        <ErrorModal error={warn || null} />
      </>
    );
  }
}

const styles = {
  linear: {
    width: 'calc(100% + 48px)',
  },
  noData: {
    color: grey[500],
    height: 300,
  },
};

export default withStyles(styles)(Boundary);

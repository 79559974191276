// modules
import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useParams } from 'react-router';

// components
import { PrivateRoute } from './PrivateRoute';
import { RedirectIfAuthRoute } from './RedirectIfAuthRoute';

// layouts
import { AuthLayout, DashboardLayout } from '../layouts';
import { useCurrentUser } from 'hooks';

// auth pages
const Login = lazy(() => import('../views/Auth/Login'));
const Restore = lazy(() => import('../views/Auth/Restore'));

// error pages
const Error404 = lazy(() => import('../views/Error/Error404'));

// account pages
const AccountCreate = lazy(() => import('../views/Accounts/Create'));
const AccountEdit = lazy(() => import('../views/Accounts/Edit'));
const AccountList = lazy(() => import('../views/Accounts/List'));

// user pages
const UserCreate = lazy(() => import('../views/Users/Create'));
const UserEdit = lazy(() => import('../views/Users/Edit'));
const UserList = lazy(() => import('../views/Users/List'));

// site pages
const SiteCreate = lazy(() => import('../views/Sites/Create'));
const SiteEdit = lazy(() => import('../views/Sites/Edit'));
const SiteList = lazy(() => import('../views/Sites/List'));

// unit pages
const UnitCreate = lazy(() => import('../views/Units/Create'));
const UnitEdit = lazy(() => import('../views/Units/Edit'));
const UnitList = lazy(() => import('../views/Units/List'));

// payment pages
const PaymentsList = lazy(() => import('views/Payments/List'));
const MonthlyPaymentReport = lazy(() => import('views/Payments/MonthlyPaymentReport'));

// ord pages
const OrdContractsList = lazy(() => import('../views/Ord/OrdContracts/OrdContractsList'));
const OrdContractCreate = lazy(() => import('../views/Ord/OrdContracts/OrdContractCreate'));
const OrdContractEdit = lazy(() => import('../views/Ord/OrdContracts/OrdContractEdit'));

const AvailableMonthsByYear = lazy(() => import('../views/Ord/OrdInvoices/AvailableMonthsByYear'));
const OrdInvoicesOfMonth = lazy(() => import('../views/Ord/OrdInvoices/OrdInvoicesOfMonth'));
const EridUploadChoosePeriod = lazy(() => import('../views/Ord/EridUpload/EridUploadChoosePeriod'));
const EridUploadByMonth = lazy(() => import('../views/Ord/EridUpload/EridUploadByMonth'));
const InvoiceReport = lazy(() => import('../views/Ord/OrdInvoices/InvoiceReport'));
const EridExport = lazy(() => import('../views/Ord/EridUpload/EridExport'));

// report pages
const ReportsPage = lazy(() => import('views/Reports/ReportsPage'));

export const Routes = () => {
  const params = useParams();

  const { hasUser } = useCurrentUser();

  return (
    <Switch>
      <PrivateRoute exact path="/" shouldRedirectToMain />

      <RedirectIfAuthRoute exact path="/auth/login" component={AuthLayout(Login)} />
      <RedirectIfAuthRoute exact path="/auth/restore" component={AuthLayout(Restore)} />

      <PrivateRoute exact path="/auth" shouldRedirectToMain />

      <PrivateRoute
        exact
        path="/accounts/list"
        component={DashboardLayout(AccountList)}
        entity="accounts"
        operation="read"
      />
      <PrivateRoute
        exact
        path="/accounts/create"
        component={DashboardLayout(AccountCreate)}
        entity="accounts"
        operation="create"
      />
      <PrivateRoute
        exact
        path="/accounts/:id/edit"
        component={DashboardLayout(AccountEdit)}
        entity="accounts"
        operation="edit"
      />

      <PrivateRoute exact path="/users/list" component={DashboardLayout(UserList)} entity="users" operation="read" />
      <PrivateRoute
        exact
        path="/users/create"
        component={DashboardLayout(UserCreate)}
        entity="users"
        operation="create"
      />
      <PrivateRoute
        exact
        path="/users/:id/edit"
        component={DashboardLayout(UserEdit)}
        entity="users"
        operation="edit"
      />

      <PrivateRoute exact path="/sites/list" component={DashboardLayout(SiteList)} entity="sites" operation="read" />
      <PrivateRoute
        exact
        path="/sites/create"
        component={DashboardLayout(SiteCreate)}
        entity="sites"
        operation="create"
      />
      <PrivateRoute
        exact
        path="/sites/:id/edit"
        component={DashboardLayout(SiteEdit)}
        entity="sites"
        operation="edit"
      />

      <PrivateRoute exact path="/units/list" component={DashboardLayout(UnitList)} entity="units" operation="read" />
      <PrivateRoute
        exact
        path="/units/create"
        component={DashboardLayout(UnitCreate)}
        entity="units"
        operation="create"
      />
      <PrivateRoute
        exact
        path="/units/:id/edit"
        component={DashboardLayout(UnitEdit)}
        entity="units"
        operation="edit"
      />
      <PrivateRoute
        exact
        path="/units/:id/preview"
        component={DashboardLayout(UnitEdit)}
        entity="units"
        operation="read"
      />
      <PrivateRoute exact path="/units/:id/updated" component={<Redirect to={`/units/${params.id}/edit`} />} />

      <PrivateRoute exact path="/payments" component={DashboardLayout(PaymentsList)} />
      <PrivateRoute exact path="/payments/:year/:month" component={DashboardLayout(MonthlyPaymentReport)} />

      <PrivateRoute exact path="/ord/contract" component={DashboardLayout(OrdContractsList)} />
      <PrivateRoute exact path="/ord/contract/create" component={DashboardLayout(OrdContractCreate)} />
      <PrivateRoute exact path="/ord/contract/:contractId/edit" component={DashboardLayout(OrdContractEdit)} />

      <PrivateRoute exact path="/ord/invoice" component={DashboardLayout(AvailableMonthsByYear)} />
      <PrivateRoute exact path="/ord/invoice-report/:invoiceId" component={DashboardLayout(InvoiceReport)} />
      <PrivateRoute exact path="/ord/invoice/:year" component={DashboardLayout(AvailableMonthsByYear)} />
      <PrivateRoute exact path="/ord/invoice/:year/:month" component={DashboardLayout(OrdInvoicesOfMonth)} />

      <PrivateRoute exact path="/ord/erid-initial" component={DashboardLayout(EridUploadChoosePeriod)} />
      <PrivateRoute exact path="/ord/erid-initial/:year" component={DashboardLayout(EridUploadChoosePeriod)} />
      <PrivateRoute exact path="/ord/erid-initial/:year/:month" component={DashboardLayout(EridUploadByMonth)} />

      <PrivateRoute exact path="/ord/erid-export" component={DashboardLayout(EridUploadChoosePeriod)} />
      <PrivateRoute exact path="/ord/erid-export/:year" component={DashboardLayout(EridUploadChoosePeriod)} />
      <PrivateRoute exact path="/ord/erid-export/:year/:month" component={DashboardLayout(EridExport)} />

      {/* no layout because of history.replace({ search }) side-effect */}
      <PrivateRoute exact path="/reports" entity="reports" operation="read" component={ReportsPage} />

      <Route component={hasUser ? DashboardLayout(Error404) : AuthLayout(Error404)} />
    </Switch>
  );
};

// modules
import ApolloBoost, { InMemoryCache } from 'apollo-boost';
import { withClientState } from 'apollo-link-state';

// utils
import Storage from 'utils/storage';
import { history } from 'routing/history';

// assets
import { ACCOUNT_FILTER, REPORT, SITE_FILTER } from 'assets/constants';

// config
import { configService } from '../../configService';

const { clearStorage } = new Storage(localStorage);
const { clearStorage: clearSessionStorage } = new Storage(sessionStorage);

const cache = new InMemoryCache({ addTypename: false });

const resetAuth = () => {
  const { pathname } = history.location;

  if (!(pathname.includes('/auth') || pathname.includes('/errors'))) {
    history.push('/auth/login');
  }

  cache.reset();
  clearStorage([SITE_FILTER, ACCOUNT_FILTER]);
  clearSessionStorage([REPORT]);
};

const authErr = 'Unauthenticated.';

const onError = (errors) => {
  const { graphQLErrors, response } = errors || {};

  if (!response) {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ debugMessage, message }) => {
      if (debugMessage === authErr || message === authErr) {
        resetAuth();
      }
    });
  } else {
    resetAuth();
  }
};

export const client = new ApolloBoost({
  cache,
  clientState: withClientState({ cache }),
  credentials: 'include',
  uri: () => configService.REACT_APP_API_URL || 'https://adx-api.otm-r.com/graphql',
  onError,
});

// modules
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// components
import { makeStyles, AppBar, Toolbar } from '@material-ui/core';
import LanguageSelect from 'components/LanguageSelect';

// utils
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLabel: {
    color: 'white',
    fontSize: '1.5rem',
    fontFamily: 'Roboto',
  },
  languageSelect: {
    position: 'absolute',
    right: 20,
    top: 15,
  },
}));

const AuthTopBar = (props) => {
  const { className, ...rest } = props;

  const css = useStyles();

  return (
    <AppBar {...rest} className={clsx(css.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/accounts/list">
          <div className={css.logoContainer}>
            <h1 className={css.logoLabel}>OTM SSP</h1>
          </div>
        </RouterLink>

        <LanguageSelect className={css.languageSelect} />
      </Toolbar>
    </AppBar>
  );
};

export { AuthTopBar };

import { gql } from 'apollo-boost';
import { paginatorPageLength } from '../../../assets/constants';

const fetchOneAccount = gql`
  query fetchOneAccount($id: ID) {
    item: account(id: $id) {
      id
      name
      type
      host_account_id
      main_user_id
      is_active
      domain
      legacy_id
      legal_entity
      domain_block_list
      revshare
      payment_info
      white_label_domain
      logo_url
      imp_tracking_url
      is_ssp_invoice

      users {
        id
        name
        email
      }

      counterparty {
        id
        inn
        name
        type
        alt_inn
        reg_number
        epay_number
        phone
        oksm
      }
    }
  }
`;

/**
 *
 * @param {number} [first]
 */
const fetchAllAccounts = (first) => gql`
  query fetchAllAccounts($page: Int, $orderBy: OrderBy, $search: String, $type: AccountType, $using_account: ID) {
    items: accounts(first: ${first ||
      paginatorPageLength}, search: $search, type: $type, page: $page, orderBy: $orderBy, using_account: $using_account) {
      data {
        id
        name

        supply_manager {
          id
          name
        }

        main_user_email
        created_at

        host_account {
          id
          name
        }

        counterparty {
          id
          inn
          name
          type
          alt_inn
          reg_number
          epay_number
          phone
          oksm
        }
      }

      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
    }
  }
`;

const fetchCounterparties = gql`
  query suggestSearchCounterparty($search: String!) {
    items: suggestSearchCounterparty(search: $search) {
      id
      inn
      name
      type
    }
  }
`;

const updateAccount = gql`
  mutation updateAccount($id: ID!, $input: AccountInput!) {
    response: updateAccount(id: $id, input: $input) {
      id
    }
  }
`;

const createAccount = gql`
  mutation createAccount($input: AccountInput!) {
    response: createAccount(input: $input) {
      id
    }
  }
`;

export { fetchOneAccount, fetchAllAccounts, fetchCounterparties, updateAccount, createAccount };

import { gql } from 'apollo-boost';

const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      is_verified
      success
    }
  }
`;

const logout = gql`
  mutation logout {
    logout
  }
`;

const resendConfirmationEmail = gql`
  mutation resendConfirmationEmail($email: String!) {
    sent: resendConfirmationEmail(email: $email)
  }
`;

const currentUser = gql`
  query currentUser {
    me {
      id
      is_read_only
      account_id
      is_admin
      locale
      name
      role
      account {
        type
      }
    }
  }
`;

const checkToken = gql`
  query checkToken {
    checkToken {
      token
      expiration_date
    }
  }
`;

const refreshToken = gql`
  mutation refreshToken {
    refreshToken {
      token
      expiration_date
    }
  }
`;

const registration = gql`
  mutation registration($input: UserRegistrationInput!) {
    register(input: $input) {
      id
    }
  }
`;

const confirmEmail = gql`
  mutation confirmEmail($input: UserEmailConfirmInput!) {
    confirmEmail(input: $input) {
      id
    }
  }
`;

const resetPassword = gql`
  mutation resetPassword($input: UserResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export {
  login,
  logout,
  resendConfirmationEmail,
  currentUser,
  checkToken,
  refreshToken,
  registration,
  confirmEmail,
  resetPassword,
  forgotPassword,
};

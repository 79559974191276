// modules
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// components
import { useTheme, makeStyles } from '@material-ui/styles';
import { AsyncPaginate } from 'react-select-async-paginate';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

// hooks
import { useApolloClient } from '@apollo/react-hooks';

// graphql
import { fetchAllAccounts, fetchOneAccount } from 'graphql/requests/accounts';

// utils
import Storage from 'utils/storage';

// assets
import i18n from 'i18n/consts';
import { gqlPolicy } from 'assets/constants/graphql';
import { ACCOUNT_FILTER } from 'assets/constants';

// styles
import './styles.scss';

// static
const { getStorageItem } = new Storage(localStorage);

const color = '#546e7a';
const boxShadow = '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)';

const font = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: 14,
  color: 'black',
};

const iconsStyles = (provided) => ({ ...provided, color });
const fontStyles = (provided) => ({ ...provided, ...font, color });

const menuStyles = (provided) => ({
  ...provided,
  border: 'none',
  boxShadow,
  zIndex: '9999 !important',
  position: 'absolute',
});

const useStyles = makeStyles(() => ({
  label: {
    padding: '0 5px',
    color: '#263238',
    fontSize: 11,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    position: 'relative',
    top: 5,
    left: 10,
    zIndex: 1,
    backgroundColor: 'white',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const underlinedControl = {
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: '1px solid',
  borderRadius: 0,
  borderColor: 'grey !important',
  boxShadow: 'none !important',
  '&:hover': {
    borderBottom: '1px solid black',
  },
};

const underlinedContainer = {
  '&:focus': {
    outline: 'none',
  },
};

export const getSelectStyleProps = ({ theme, isUnderlined, styles, menuListMaxHeight, error, height }) => ({
  theme: (th) => ({
    ...th,
    colors: {
      ...th.colors,
      primary: theme.palette.primary.main,
      primary25: theme.palette.primary.light,
    },
  }),
  styles: {
    container: (provided) => ({ ...provided, ...(isUnderlined && underlinedContainer) }),
    indicatorSeparator: iconsStyles,
    dropdownIndicator: iconsStyles,
    noOptionsMessage: fontStyles,
    clearIndicator: iconsStyles,
    placeholder: iconsStyles,
    singleValue: (provided) => ({
      ...provided,
      ...font,
      color,
      ...styles?.singleValue,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: height || 52,
      border: 'none',
      boxShadow,
      ...font,
      ...styles?.control,
      ...(error && { border: '1px solid red' }),
      ...(isUnderlined && underlinedControl),
    }),
    option: (provided) => ({ ...provided, ...font, ...styles?.option }),
    menu: menuStyles,
    menuList: (provided) => ({
      ...provided,
      maxHeight: menuListMaxHeight ?? provided.maxHeight,
    }),
    multiValue: (provided) => ({ ...provided, ...styles?.multiValue }),
    multiValueRemove: (provided) => ({ ...provided, ...styles?.multiValueRemove }),
  },
});

/**
 * props params
 * @param {string} [className]
 * @param {object} [styles]
 * @param {boolean} [async]
 * @param {string} [accountType]
 * @param {boolean} [disabled]
 * @param {boolean} [error]
 * @param {number} [height]
 * @param {boolean} [isClearable]
 * @param {boolean} [isCreatable]
 * @param {boolean} [isMulti]
 * @param {boolean} [isUnderlined]
 * @param {(string, array) => void} [loadOptions]
 * @param {array} [options]
 * @param {string} placeholder
 * @param {string} selectLabel
 * @param {boolean} [withAccounts]
 * @param {object} value
 * @param {(value: any, actionMeta: ActionMeta) => void} onChange
 */
const SelectSSP = (props) => {
  const {
    className,
    styles,
    accountType,
    disabled,
    error,
    height,
    menuListMaxHeight,
    isAsync,
    isClearable,
    isCreatable,
    isMulti,
    isUnderlined,
    loadOptions,
    loadOptionsOnMenuOpen,
    noOptionsMessage,
    options,
    placeholder,
    selectLabel,
    withAccounts,
    value,
    onChange,
    closeMenuOnSelect,
  } = props;

  const css = useStyles();
  const theme = useTheme();

  const { t } = useTranslation();

  const client = useApolloClient();

  // state
  const [parsedValue, setValue] = useState(value || null);

  // effects
  useEffect(() => {
    if (value && withAccounts && typeof value !== 'object') {
      client
        .query({
          query: fetchOneAccount,
          fetchPolicy: gqlPolicy.NETWORK_ONLY,
          variables: {
            id: value,
          },
        })
        .then(({ data }) => {
          const name = data?.item?.name;
          const label = name ? `${name} #${value}` : `#${value}`;
          setValue({ value, label });
        });
    } else {
      setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // methods

  const parseAccounts = (accounts) =>
    accounts.map(({ id, name, main_user_email }) => ({
      value: id,
      label: `${name} #${id}`,
      email: main_user_email || undefined,
    }));

  const loadAccountOptions = async (search, prevOptions) => {
    const PAGE_SIZE = 15;
    const page = Math.floor(prevOptions.length / PAGE_SIZE) + 1 || 1;

    const currentAccount = getStorageItem(ACCOUNT_FILTER);

    const {
      data: { items },
    } = await client.query({
      query: fetchAllAccounts(PAGE_SIZE),
      fetchPolicy: 'network-only',
      variables: {
        page,
        search,
        ...(currentAccount && { using_account: currentAccount.value }),
        type: accountType || 'ALL',
      },
    });

    const hasMore = page !== items.paginatorInfo.lastPage;
    const parsed = parseAccounts(items.data);

    return {
      options: parsed,
      hasMore,
    };
  };
  // constants

  const styleProps = useMemo(
    () => getSelectStyleProps({ theme, isUnderlined, styles, menuListMaxHeight, error, height }),
    [theme, isUnderlined, styles, menuListMaxHeight, error, height]
  );

  const commonProps = {
    className: className || '',
    isClearable,
    isDisabled: disabled,
    isMulti,
    placeholder,
    value: parsedValue,
    onChange,
    noOptionsMessage,
    closeMenuOnSelect,
  };

  const CurrentSelect = () => {
    if (isAsync) {
      return (
        <AsyncPaginate
          style={{ zIndex: 3300 }}
          {...styleProps}
          {...commonProps}
          debounceTimeout={1000}
          loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
          loadOptions={withAccounts ? loadAccountOptions : loadOptions}
        />
      );
    }

    if (isCreatable) {
      return <CreatableSelect {...styleProps} {...commonProps} options={options} />;
    }

    return <Select {...styleProps} {...commonProps} noOptionsMessage={() => t(i18n.noData)} options={options} />;
  };

  return selectLabel ? (
    <div className={css.container}>
      <div className={css.label}>{selectLabel}</div>
      <CurrentSelect />
    </div>
  ) : (
    <CurrentSelect />
  );
};

SelectSSP.defaultProps = {
  loadOptionsOnMenuOpen: true,
  noOptionsMessage: () => 'No options',
  placeholder: 'Select...',
  value: null,
  onChange: () => {},
  closeMenuOnSelect: true,
};

export default SelectSSP;

// modules
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// components
import { LinearProgress } from '@material-ui/core';

// hooks
import { useCurrentUser } from 'hooks';

// config
import { rolesConfig } from 'assets/rolesConfig';

const PrivateRoute = (props) => {
  const { component: Component, computedMatch, entity, operation, shouldRedirectToMain, ...otherProps } = props;

  const { hasUser, user, loading } = useCurrentUser();
  const { role, is_read_only } = user;

  // constants
  const mainPageRoute = is_read_only ? '/sites/list' : '/accounts/list';

  const hasAccessToCurrentEntity = operation === 'read' ? rolesConfig[entity][operation][role] : !is_read_only;

  return (
    <Route
      {...otherProps}
      computedMatch={computedMatch}
      render={() => {
        if (loading) {
          return <LinearProgress color="secondary" />;
        }

        if (hasUser) {
          if (shouldRedirectToMain) {
            return <Redirect to={mainPageRoute} />;
          }

          if (hasAccessToCurrentEntity) {
            return <Component />;
          }

          return <Redirect to="/sites/list" />;
        }

        return <Redirect to="/auth/login" />;
      }}
    />
  );
};

export { PrivateRoute };
